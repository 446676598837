/* ==========================================================================
	MIXINS
========================================================================== */

// breakpoint mixins
@use "sass:math";

@mixin border-radius-fix($w) {
  @include border-top-left-radius($w);
  @include border-top-right-radius($w);
  @include border-bottom-left-radius($w);
  @include border-bottom-right-radius($w);
}

@mixin respond-to($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin respond-from($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-to-from($minWidth, $maxWidth) {
  @media (min-width: $minWidth) and (max-width: $maxWidth) {
    @content
  }
}

@mixin respond-to-height($height) {
  @media only screen and (max-height: $height) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content
  }
}

@mixin large-desktop {
  @media (min-width: $largeDesktop) {
    @content
  }
}

@mixin tablet-landscape {
  @media (min-width: $tablet) and (max-width: $tabletLandscape) {
    @content
  }
}

@mixin tablet-portrait {
  @media (min-width: $phone + 1) {
    @content
  }
}

@mixin tablet {
  @media (min-width: $phone + 1) and (max-width: $tablet) {
    @content
  }
}

@mixin tablet-desktop {
  @media (min-width: $phone + 1) and (max-width: $largeDesktop - 1) {
    @content
  }
}

@mixin phone-landscape {
  @media (min-width: $phone + 1) and (max-width: $tablet) {
    @content
  }
}

@mixin phone {
  @media (max-width: $phone) {
    @content;
  }
}

@mixin above-phone {
  @media (min-width: $phone + 1) {
    @content;
  }
}

@mixin above-phone-device {
  @media (min-width: $phone - 1) {
    @content;
  }
}

@mixin above-tablet-portrait {
  @media (min-width: $tabletPortrait + 1) {
    @content;
  }
}

@mixin above-tablet {
  @media (min-width: $respHeader + 1) {
    @content;
  }
}

@mixin above-laptop {
  @media (min-width: $laptop) {
    @content;
  }
}

@mixin above-wrapper {
  @media (min-width: $wrapper) {
    @content;
  }
}

@mixin above-small-phone {
  @media (min-width: $resp500 + 1) {
    @content
  }
}

// aspect ratio
// rewritten, simplified
@mixin aspect-ratio($w, $h) {
  $slope: math.div($h, $w);
  $height: $h - $w * $slope;
  padding-top: $slope * 100%;
  height: $height;
}

@mixin user-select($select) {
  $select: unquote($select);
  @include experimental(user-select, $select, -moz, -webkit, not -o, not -ms, -khtml, official);
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($def) {
  -webkit-animation: $def;
  /* Safari 4+ */
  -moz-animation: $def;
  /* Fx 5+ */
  -o-animation: $def;
  /* Opera 12+ */
  animation: $def;
  /* IE 10+ */
}

// add a slash before your item
@mixin slash($_height: 10px, $_margin: 5px, $_width: 4px, $_skew: -20deg, $_color: #fff) {
  &:before {
    background-color: $_color;
    display: inline-block;
    content: ' ';
    height: $_height;
    margin-right: $_margin;
    width: $_width;
    @include transform(skewX($_skew));

    .rtl & {
      margin: {
        left: $_margin;
        right: 0;
      }
    }
  }
}

// add an arrow after your item
@mixin arrow($position: 'down', $_height: 6px, $_width: 8px, $_color: black) {
  &:after {
    border-style: solid;
    content: " ";
    display: inline-block;
    height: 0px;
    margin-bottom: $_height * 0.5;
    width: 0px;

    @if $position=='down' {
      border-color: $_color transparent transparent transparent;
      border-width: $_height $_width * 0.5 0 $_width * 0.5;
    }

    @else if $position=='up' {
      border-color: transparent transparent $_color transparent;
      border-width: 0 $_width * 0.5 $_height $_width * 0.5;
    }

    @else if $position=='left' {
      border-color: transparent $_color transparent transparent;
      border-width: $_height * 0.5 $_width $_height * 0.5 0;
    }

    @else if $position=='right' {
      border-color: transparent transparent transparent $_color;
      border-width: $_height * 0.5 0 $_height * 0.5 $_width;
    }
  }
}

// background gradients that work in IE
@mixin background-image-with-ms($image) {
  @include background-image($image); // Compass default version, for everything but IE 10.
  background-image: -ms-#{$image}; // Vendor prefixes for IE 10.
}

@mixin clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }

  &:after {
    clear: both;
  }
}


/*
Efrain: I had to modify this sass mixin a little bit
https://raw.githubusercontent.com/jorenvanhee/sass-burger/master/_burger.scss */

/**
 * Burger parts
 *
 * (---) top    -> &:before
 * [---] middle -> &
 * (---) bottom -> &:after
 */

/**
 * Burger
 */

@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  &,
  &:before,
  &:after {
    display: block;
    width: $width;
    height: $height;
    background-color: $color;

    @if $border-radius !=0 {
      border-radius: $border-radius;
    }

    transition-property: background-color,
    transform;
    transition-duration: $transition-duration;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    top: -($height + $gutter);
  }

  &:after {
    top: $height + $gutter;
  }
}


/**
 * Select parts of the burger
 */

@mixin burger-parts {

  &,
  &:before,
  &:after {
    @content;
  }
}

@mixin burger-top {
  &:before {
    @content;
  }
}

@mixin burger-middle {
  & {
    @content;
  }
}

@mixin burger-bottom {
  &:after {
    @content;
  }
}

/**
 * Burger animations
 */

@mixin burger-to-cross($burger-height: 5px, $burger-gutter: 30px) {
  & {
    background-color: transparent;
  }

  &:before {
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }

  &:after {
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}

.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);

  &>a:focus,
  &>input:focus,
  &>button:focus {
    position: static;
    width: auto;
    height: auto;
  }
}