/* ==========================================================================
	ICONS
========================================================================== */

%icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  font: {
    family: 'icomoon';
    style: normal;
    variant: normal;
    weight: normal;
  }

  line-height: 1;
  speak: none;
  text-transform: none;
}

%icon--facebook::before {
  @extend %icon;
  content: '\ea90';
}

%icon--twitter::before {
  @extend %icon;
  content: '\ea96';
}

%icon--vimeo::before {
  @extend %icon;
  content: '\eaa0';
}

%icon--video::before {
  @extend %icon;
  content: '\e900';
}

%icon--video-filled::before {
  @extend %icon;
  content: '\e90f';
}

%icon--video-filled-alt:after {
  @extend %icon;
  content: '\e90f';
}

%icon--account::before {
  @extend %icon;
  content: '\e901';
}

%icon--basket::before {
  @extend %icon;
  content: '\e902';
}

%icon--directions::before {
  @extend %icon;
  content: '\e903';
}

%icon--point::before {
  @extend %icon;
  content: '\e904';
}

%icon--point-filled::before {
  @extend %icon;
  content: '\e906';
}

%icon--search::before {
  @extend %icon;
  content: '\e905';
}

%icon--home::before {
  @extend %icon;
  content: '\e909';
}

%icon--phone::before {
  @extend %icon;
  content: '\e907';
}

%icon--close::before {
  @extend %icon;
  content: '\e908';
}

%icon--circle-right::before {
  @extend %icon;
  content: '\e90a';
}

%icon--arrow-up::before {
  @extend %icon;
  content: '\e90b';
}

%icon--arrow-right::before,%icon--arrow-right-before {
  @extend %icon;
  content: '\e90c';
}

%icon--arrow-left::before,%icon--arrow-left-before {
  @extend %icon;
  content: '\e90d';
}

%icon--arrow-down::before {
  @extend %icon;
  content: '\e90e';
}

%icon--plus::before {
  @extend %icon;
  content: '\e910';
}

%icon--star::before,%icon--star-before {
  @extend %icon;
  content: '\f005';
}

%icon--star-o::before,%icon--star-o-before {
  @extend %icon;
  content: '\f006';
}

%icon--caret-down::before {
  @extend %icon;
  content: '\f007';
}

%icon--caret-up::before {
  @extend %icon;
  content: '\f008';
}

%icon--caret-left::before {
  @extend %icon;
  content: '\f009';
}

%icon--caret-right::before {
  @extend %icon;
  content: '\f00a';
}