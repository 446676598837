/* ==========================================================================
	SEARCH RESULTS
========================================================================== */

.search-results {
	background: url('/assets/images/ajax-loader.gif') center center no-repeat $mapContentBg;
	position: relative;

	@include phone {
		.grid {
			display: block;
		}
	}

	@include above-phone {
		background-position: 75% center;

		.heading--tertiary {
			display: inline-block;
			margin: 20px;
			width: calc(100% - 90px);
		}
	}
}

.search-results__header {
	display: none;
	position: relative;
	z-index: 9;

	@include above-phone {
		background: $mapContentBg;
		display: block;
		padding-top: 130px;
		position: fixed;
		top: 20px;
		width: calc(50% - 1px);

		.header-map & {
			display: none;
		}

		.header-map--active & {
			display: block;
		}
	}
}

.search-results__items {
	opacity: 0;
	position: relative;
	transition: opacity $menuSpeed;
	transition-delay: 0.5s;

	.search-results--active & {
		opacity: 1;
	}

	@include above-phone {
		padding-top: 170px;
	}
}

.search-results__item {
	cursor: pointer;
	position: relative;
	z-index: 1;

	&--active, &:hover {
		background: $mapContentActive;

		.button--inverse-alt {
			border-color: $white;
			color: $white;
		}
	}

	&--no-results {
		cursor: default;
	}

	.button--inverse-alt {
		display: block;
		max-width: none;
		min-height: inherit;
		text-align: center;

		@include above-phone {
			margin-left: -15px;
			padding: 5px;
			width: calc(100% + 15px);
		}
	}
}

.search-results__map {
	height: calc(50vh - 50px);
	margin-top: 100px;
	opacity: 0;	
	transition: opacity $menuSpeed;
	transition-delay: 0.5s;
	width: 100vw;
	z-index: 2;

	.search-results--active & {
		opacity: 1;
	}

	.gm-style img { 
		max-width: none; 
	}
	
	.gm-style label {
		display: inline; 
		width: auto;
	}

	@include above-phone {
		height: 100vh;
		margin-top: 0;
		width: 100vw;

		.search-results--off-centre & {
			width: 150vw;
		}
	}
}

.search-results__directions {
	@extend %icon--directions;
	color: $iconLink;
	display: block;
	font: {
		size: 1.3rem;
		weight: 600;
	}
	padding: 0 0 15px 25px;
	position: relative;
	// Leaving commented until fully tested
	//text-transform: capitalize;

	@include large-desktop {
		padding-left: 40px;
	}

	.search-results__item--active &, .search-results__item:hover & {
		color: $white;

		&:before {
			color: $white;
		}
	}

	.no-touchevents & {
		&:hover {
			color: $white;

			&:before {
				color: $white;
			}
		}
	}

	&:before {
		color: $iconLink;
		font: {
			size: 1.8rem;
			weight: bold;
		}
		left: 0;
		position: absolute;
		top: 0;
		vertical-align: middle;

		@include large-desktop {
			font-size: 2.8rem;
		}
	}
}

.search-results__description {
	color: $mapColor;
	font: {
		size: 1.2rem;
		weight: 600;
	} 
	// Leaving commented until fully tested
	//text-transform: capitalize;

	span {
		color: $brandPrimary;
		display: block;
	}

	.search-results__item--active &, .search-results__item:hover & {
		color: $white;
	}
}

.search-results__details {
	background: $mapContentBg;
	border: {
		color: $borderColor;
		style: solid;
		width: 1px 0 0 0;
	}
	height: 50vh;
	overflow-y: scroll;
	padding: 10px;

	.grid__item {
		padding: 10px;

		@include above-phone {
			height: auto;
			padding: 15px;
		}

		@include respond-to($resp600) {
			&--columns-5,
			&--columns-6,
			&--columns-7, {
				flex: 1 1 100%;
			}
		}
	}

	&--loader {
		background: url('/assets/images/ajax-loader.gif') center top no-repeat $mapContentBg;

		@include above-phone {
			background-position: center 170px;
		}
	}

	@include above-phone {
		border-width: 0 1px 0 0;
		height: 100vh;
		min-height: 100vh;
		opacity: 1;
		overflow-y: auto;
		padding: 130px 15px 40px 15px;
		position: absolute;
		top: 0;
		transition: opacity $menuSpeed;
		width: 50%;
		z-index: 3;

		&--alternate {
			padding-left: 0;
			padding-right: 0;
		}

		.search-results--details-hide & {
			opacity: 0;
			z-index: 1;
		}
	}
}

.search-filter {
	border-bottom: 1px solid $borderColor;
}

.search-filter__item {
	background: $white;
	border-radius: 0;
	border: 1px solid $selectBorderColor;
	color: $field;
	display: block;
	font: {
		family: $openSans;
		size: 1.6rem;
		weight: 600;
	}
	letter-spacing: 2px;
	line-height: 1;
	margin-bottom: 20px;
	min-height: 45px;
	padding: 10px;
	vertical-align: top;
	width: 100%;

	@include above-phone {
		display: inline-block;
		margin-right: 22px;
		width: calc(37.5% - 15px);

		&--input {
			margin-left: 15px;
			width: calc(37.5% - 45px);
		}
	}

	&--submit {
		background: $button;
		border-color: $brandPrimary;
		color: $white;
		// Leaving commented until fully tested
		//text-transform: capitalize;
		transition: background $menuSpeed, color $menuSpeed;

		@include above-phone {
			display: inline-block;
			margin-right: 0;
			width: calc(25% - 15px);
		}

		&:hover {
			background: $white;
			color: $button;
		}
	}

	&--select {
		padding: 0;
		position: relative;

		select {
			appearance: none;
			background: transparent;
			border: none;
			color: $field;
			display: block;
			font: {
				family: $openSans;
				size: 1.6rem;
				weight: 600;
			}
			letter-spacing: 2px;
			line-height: 1;
			min-height: 42px;
			padding: 10px;
			transition: border $menuSpeed, background $menuSpeed, color $menuSpeed;
			width: 100%;
		}

		.no-touchevents.appearance & {
			&:hover {
				background: $navigation;
				border: solid 1px $field;

				select {
					color: $white;
				}
			}

			&:hover:after {
				border: {
					bottom: solid 2px $white;
					right: solid 2px $white;
				}
				transition: border $menuSpeed;
			}
		} 

		.appearance & {
			&:after {
				border: {
					bottom: solid 2px $navigation;
					right: solid 2px $navigation;
				}
				border-radius: 1px;
				content: '';
				display: block;
				height: 7px;
				position: absolute;
				right: 15px;
				top: calc(50% - 1px);
				transform: translateY(-50%) rotate(45deg);
				width: 7px;
				z-index: 2;
			}
		}
		
	}
}
