/* ==========================================================================
	BUTTON
========================================================================== */

.button {
  background: $button;
  border: none;
  border-radius: 0;
  color: $white;
  display: inline-block;

  font: {
    size: 1.4rem;
    weight: 600;
  }

  min-height: 42px;
  padding: 11px 15px;

  text: {
    align: center;
    // Leaving commented until fully tested
    //transform: capitalize;
  }

  transition: background $menuSpeed,
  border $menuSpeed,
  color $menuSpeed;
  vertical-align: middle;
  width: 100%;

  .no-touchevents & {
    &:hover {
      background: $white;
      color: $button;
    }
  }

  &--ghost {
    background: 0;
    border: 1px solid $black;
    color: $black;
  }

  &--image {
    border: 0;
    background: 0;
    padding: 0;
    margin: 10px;
  }

  &--text {
    border: 0;
    background: 0;
    color: $black;
    text-decoration: underline;
    padding: 0;
    margin: 0 10px;
  }


  // BUTTON CONTAINER

  &-container {
    display: block;

    &--inline {
      margin: 0 -10px;

      .button {
        display: inline-block;
        width: auto;
      }
    }

    &--centered {
      text-align: center;

      .button {
        display: inline-block;
        width: auto;
      }
    }
  }
}

.button--condensed {
  width: auto;
}

.button--spaced {
  margin-bottom: 10px;
}

.button--inverse {
  background: none;
  border: 1px solid $white;

  .no-touchevents & {
    &:hover {
      background: $white;
      border-color: transparent;
      color: $heading;
    }
  }
}

.button--inverse-alt {
  background: none;
  border: 1px solid $headerColor;
  color: $headerColor;
  transition: border-color $menuSpeed, color $menuSpeed;

  .no-touchevents & {
    &:hover {
      background: none;
      border-color: $button;
      color: $button;
    }
  }
}

.button--inverse-solid {
  background: $black;
  color: $white;
  transition: border-color $menuSpeed, color $menuSpeed;

  .no-touchevents & {
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

@include above-phone {
  .button--right {
    float: right;
  }
}