/* ==========================================================================
	BANNER
========================================================================== */

.banner {
  background: $black;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--home {
    overflow: inherit;
  }

  &--products {
    overflow: inherit;
	  padding-bottom: 50px;
    padding-top: 180px;


    .heading {
      color: $white;
      display: block;
      position: relative;
      text-align: center;
      width: 100%;
      z-index: 10;

      &--secondary {
        margin-bottom: 5px;
      }

      &--tertiary {
        margin-bottom: 10px;
      }
    }

    .text {
        text-align: center;
        color: $white;
        position: relative;
        z-index: 10;
        padding-top: 20px;
    }

    .slider {
      margin: auto;
      max-width: 1400px;
      padding: 20px;
      width: 100%;
      z-index: 10;

      &--centered-slides {
        .slick-track {
          margin: auto;
        }
      }
    }

    .slick-list {
      padding-left: 0 !important;
    }

    .slick-track {
      display: flex;
      width: 100%;
    }

    .slider__slide {
      align-items: center; //optional
      display: flex;
      height: auto;
      justify-content: center; //optional
      padding: 0 10px;
      position: relative;
    }

    .slick-dots {
      display: block;
      padding: 40px 0;
      text-align: center;

      button {
        &:hover {
          background: $brandPrimary;
        }
      }
    }

    .slick-arrow {
      background: 0;
      border: 0;
      color: $white;
      font-size: 0;
      opacity: 1;
      position: absolute;
      top: 40%;
      transition: color $transitionSpeed * 2, opacity $transitionSpeed * 2, transform $transitionSpeed * 2;

      &:hover {
        color: $brandPrimary;
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0;
      }

      &::after,
      &::before {
        font-size: 5rem;
      }

      &.slick-prev {
        left: 0;
        transform: translate(-100%, -50%);

        &::before {
          @extend %icon--arrow-left-before;
        }
      }

      &.slick-next {
        right: 0;
        transform: translate(100%, -50%);

        &::before {
          @extend %icon--arrow-right-before;
        }
      }
    }
  }

  

  &::after {
    // background: url('/assets/images/pattern.png') 0 0 repeat transparent;
    // background-color: rgba($black, .35);
    // content: '';
    // display: block;
    // height: 100%;
    // left: 0;
    // position: absolute;
    // top: 0;
    // width: 100%;
    // z-index: 2;
    display: none;
  }

  &--wash-club {
    &::after {
      display: none;
    }
  }
}

.banner__image {
  background: {
    position: top center;
    repeat: no-repeat;
    size: cover;
  }
  content: '';
  display: block;
  // filter: blur(1px);
  height: calc(100% + 20px);
  left: -20px;
  opacity: .6;
  position: absolute;
  top: -20px;
  width: calc(100% + 20px);
  z-index: 1;
}

// Home Banner
.banner--home {
  border-bottom: solid 1px $white;
  min-height: 500px;
  @include aspect-ratio(1680, 820);
  height: auto; // Overwrite the include.
}

// Home Feature
.banner--feature {
  min-height: 0;
  padding: 50px 15px;

  .feature-title {
    font-style: italic;
  }

  @include above-phone {
    padding: 75px 15px;
  }

  @include desktop {
    padding: 100px 15px;
  }
}
