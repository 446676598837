/* ==========================================================================
	LOCATION
========================================================================== */
.location-legacy {
	
	.location {
		position: relative;
	}
	
	.location__map {
		height: 400px;
		width: 100vw;
		z-index: 2;

		.gm-style img { 
			max-width: none; 
		}

		.gm-style label {
			display: inline; 
			width: auto;
		}

		@include above-phone {
			height: 100vh;
			width: 50vw;
			left: 50%;
			.location--off-centre & {
				width: 150vw;
			}

		}
	}

	.location__details {
		background: $mapContentBg;
		border: {
			color: $borderColor;
			style: solid;
			width: 1px 0 0 0;
		}
		overflow-y: scroll;
		padding: 10px 10px 0;

		@include respond-to($respHeader) {
			.grid {
				flex-wrap: wrap;
			}

			.grid__item {
				&--columns-5,
				&--columns-6,
				&--columns-7 {
					flex: 1 1 100%;
				}
			}
		}

		&--loader {
			background: url('/assets/images/ajax-loader.gif') center top no-repeat $mapContentBg;

			@include above-phone {
				background-position: center 170px;
			}
		}

		@include above-phone {
			border-width: 0 1px 0 0;
			height: 100vh;
			min-height: 100vh;
			opacity: 1;
			padding: 160px 15px 0 15px;
			position: absolute;
			top: 0;
			transition: opacity $menuSpeed;
			width: 50%;
			z-index: 3;

			.location--details-hide & {
				opacity: 0;
				z-index: 1;
			}
		}

	.location__marker-content {
		padding: 5px;

		.button--inverse-alt {
			display: block;
			margin: 10px 0;
			min-height: inherit;
			text-align: center;
		}
	}

	.location__marker-title {
		@extend %heading-eyebrow;
		letter-spacing: 0.1rem;

		&:after {
			display: none;
		}
	}

	.location__marker-address,
	.location__marker-phone {
		display: block;
		font: { 
			size: 1.4rem;
			weight: 400;
		}
		padding-bottom: 10px;

		&:before {
			color: $brandPrimary;
			font: {
				size: 1.8rem;
				weight: bold;
			}
			padding-right: 10px;
		}
	}

	.location__marker-address {
		@extend %icon--home;
	}

	.location__marker-phone {
		@extend %icon--phone;
		color: $brandPrimary;

		.no-touchevents & {
			&:hover {
				color: $iconLink;

				&:before {
					color: $iconLink;
				}
			}
		}
	}

	/* Styling Updates 2021 */
	.location-content__address {
		font-family: $openSans;
	}
	.location p, span, li {
		font-family: $openSans;
	}
	.location__details .nearby-location-item div a {
		font-family: $openSans;
		text-decoration: none;
	}
}
}

/* ==========================================================================
	LOCATION
========================================================================== */
.location-new {
	.location {
		position: relative;
	}

	.location__map {
		height: 400px;
		width: 100vw;
		z-index: 2;

		.gm-style img { 
			max-width: none; 
		}
		
		.gm-style label {
			display: inline; 
			width: auto;
		}

		@include above-phone {
			height: 100vh;
			width: 100vw;

			.location--off-centre & {
				width: 150vw;
			}
		}
	}

	.location__details {
		background: $mapContentBg;
		border: {
			color: $borderColor;
			style: solid;
			width: 1px 0 0 0;
		}
		overflow-y: scroll;
		padding: 10px 10px 0;

		@include respond-to($respHeader) {
			.grid {
				flex-wrap: wrap;
			}

			.grid__item {
				&--columns-5,
				&--columns-6,
				&--columns-7 {
					flex: 1 1 100%;
				}
			}
		}

		&--loader {
			background: url('/assets/images/ajax-loader.gif') center top no-repeat $mapContentBg;

			@include above-phone {
				background-position: center 170px;
			}
		}

		@include above-phone {
			border-width: 0 1px 0 0;
			// height: 100vh;
			min-height: 100vh;
			opacity: 1;
			padding: 160px 15px 0 15px;
			position: absolute;
			top: 0;
			transition: opacity $menuSpeed;
			width: 50%;
			z-index: 3;

			.location--details-hide & {
				opacity: 0;
				z-index: 1;
			}
		}
	}

	.location__marker-content {
		padding: 5px;

		.button--inverse-alt {
			display: block;
			margin: 10px 0;
			min-height: inherit;
			text-align: center;
		}
	}

	.location__marker-title {
		@extend %heading-eyebrow;
		letter-spacing: 0.1rem;

		&:after {
			display: none;
		}
	}

	.location__marker-address,
	.location__marker-phone {
		display: block;
		font: { 
			size: 1.4rem;
			weight: 400;
		}
		padding-bottom: 10px;

		&:before {
			color: $brandPrimary;
			font: {
				size: 1.8rem;
				weight: bold;
			}
			padding-right: 10px;
		}
	}

	.location__marker-address {
		@extend %icon--home;
	}

	.location__marker-phone {
		@extend %icon--phone;
		color: $brandPrimary;

		.no-touchevents & {
			&:hover {
				color: $iconLink;

				&:before {
					color: $iconLink;
				}
			}
		}
	}
	/* Styling Updates 2021 */
	.location-content__address {
		font-family: $openSans;
	}
	.location p, span, li {
		font-family: $openSans;
	}
	.location__details .nearby-location-item div a {
		font-family: $openSans;
		text-decoration: none;
	}
	.location-style-update {
		padding-top: 102px;
		.location__details {
			padding: 0;
			border: none;
			@include above-phone {
				position: initial;
				width: 100%;
				overflow: initial;
			}
			.location-content.location-address {
				background-color: $brandPrimary;
				padding: 10px;
				h1, span, a {
					color: #fff;
					font-family: "co-headline-light",Arial,Helvetica,sans-serif;
					font-weight: 400;
				}
				h1 {
					font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
				}
				span, a {
					font-size: 18px;
				}
				.location-content__phone, .location-content__directions {
					&::before {
						color: #fff;
					}
				}
			}
		}
		.location__details_name_map_wrap {
			@include above-phone {
				display: grid; 
				grid-template-columns: 1fr 1fr; 
				grid-template-rows: 1fr;
				gap: 0px 0px; 
				grid-template-areas: 
					". .";
				height: 100%;
				background-color: $brandPrimary;
			}
		}
		.location__details_location-name, .location__map {
			@include above-phone {
				width: 100%;
				display: block;
			}
		}
		.location-content__image {
			width: 100%;
			height: auto;
			@include above-phone {
				max-height: initial;
			}
		}
		.location-services-hours {
			padding: 0;
			display: none;
			@include above-phone {
				display: grid; 
				grid-template-columns: 1fr 1fr; 
				grid-template-rows: 1fr; 
				gap: 0px 0px; 
				grid-template-areas: 
					". ."; 
			}
			h2 {
				color: $brandPrimary;
				margin-bottom: 15px;
				font-size: 22px;
				font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
				font-weight: 400;
			}
			.location-content__services {
				padding: 0;
				margin : 0;
				li {
					list-style: none;
					font-family: "co-headline-light",Arial,Helvetica,sans-serif;
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
		.location-hours {
			margin: 0;
			background-color: #daf4f9;
			@include above-phone {
				padding: 15px;
			}
			span, time {
				display: inline-block;
				font-family: "co-headline-light",Arial,Helvetica,sans-serif;
				font-weight: 400;
				font-size: 18px;
				color: $brandPrimary;
			}
			span {
				margin-right: 10px;
				min-width: 120px;
			}
			time {
				width: 50%;
				@include above-phone {
					width: 100%;
				}
			}
		}
		.location-life-promo img{
			width: 100%;
			height: auto;
			display: block;
		}
		.nearby-locations {
			h2 {
				color: $brandPrimary;
				font-size: 22px;
				font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
				font-weight: 400;
			}
			.nearby-location-wrap {
				margin: 0 0 20px 0;
				padding: 0;
				border: none;
				h3 {
					text-align: left;
					margin: 0;
					margin-bottom: 10px;
					font-size: 21px;
					color: $brandPrimary;
					font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
					font-weight: 400;
				}
				span {
					font-family: "co-headline-light",Arial,Helvetica,sans-serif;
					font-weight: 400;
					font-size: 15px;
					line-height: 24px;
					color: $brandPrimary;
					display: block;
				}
				a {
					position: initial;
					display: inline-block;
					font-size: 14px;
					margin: 20px 0 0 0;
					padding: 10px 15px;
					border-radius: 25px;
					color: #fff;
					background-color: $brandSecondary;
					font-family: "co-headline-light",Arial,Helvetica,sans-serif;
					font-weight: 400;
					width: auto;
					line-height: 22px;
					text-decoration: none;
					&:hover {
						color: $brandSecondary;
						background-color: #fff;
					}
				}
			}
		}
		.location-teaser {
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		.location-teaser-text {
			padding: 25px 15px;
			h2 {
				color: $brandPrimary;
				margin-bottom: 10px;
				font-size: 21px;
				font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
				font-weight: 400;
			}
			p {
				font-size: 15px;
				line-height: 24px;
				color: $brandPrimary;
				font-family: "co-headline-light",Arial,Helvetica,sans-serif;
				font-weight: 400;
			}
			a {
				display: inline-block;
				font-size: 14px;
				margin-top: 20px;
				padding: 10px 15px;
				border-radius: 25px;
				color: #fff;
				background-color: $brandSecondary;
				font-family: "co-headline-light",Arial,Helvetica,sans-serif;
				font-weight: 400;
				&:hover {
					color: $brandSecondary;
					background-color: #fff;
				}
			}		
		}
		.nearby-location-text {
			margin: 0;
			border: none;
		}
		.nearby-locations-intro {
			padding: 0 15px;
			h2 {
				color: $brandPrimary;
				font-size: 21px;
				font-family: "co-headline-regular",Arial,Helvetica,sans-serif;
				font-weight: 400;
				margin-bottom: 10px;
			}
			p {
				font-size: 15px;
				line-height: 24px;
				color: $brandPrimary;
				font-family: "co-headline-light",Arial,Helvetica,sans-serif;
				font-weight: 400;
			}
		}
		.location-header {
			position: relative;
			@include above-phone {
				height: 80vh;
				overflow: hidden;
			}
			img {
				width:100%;
				height:auto;
				display:block;
				@include above-phone {
					transform: translate(0, -50vh);
					margin-top: 25vh;
				}
			}
			h1 {
				font-family: "co-headline-light",Arial,Helvetica,sans-serif;
				font-weight: 400;
				letter-spacing: 3px;
				color: #fff;
				position: absolute;
				bottom: 15px;
				padding: 0 15px;
				font-size: 25px;
				line-height: 28px;
				margin: 0;
				max-width: 325px;
				@include above-phone {
					font-size: 96px;
					line-height: 105px;
					bottom: initial;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: initial;
					width: 100%;
					text-align: center;
				}
			}
			.location-header-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				background-color: rgba(0,0,0,0.25);
			}
		}
		.location__map {
			border-bottom: 4px solid $brandPrimary;
			@include above-phone {
				height: 100%;
				border-bottom: none;
			}
		}
		.location-mobile-hide {
			display: none;
			@include above-phone {
				display: block;
			}
		}
		.location-desktop-hide {
			display: block;
			@include above-phone {
				display: none;
			}
		}
		.location-services {
			@include above-phone {
				padding: 15px;
				background-color: #f1f1f1;
				border-bottom: 3px solid #fff;
			}
		}
		.location-lower-wrap {
			@include above-phone {
				padding: 90px 0 0;
				max-width: 1200px;
				margin: auto;
			}
		}
		.location-teaser-wrap {
			@include above-phone {
				display: grid; 
				grid-template-columns: 1fr 1fr; 
				grid-template-rows: 1fr; 
				gap: 0px 30px; 
				grid-template-areas: 
					". ."; 
				height: 100%; 
			}
		}
		.bannersection--download-save-2800 {
			@include above-phone {
				border: none;
			}
		}
		.location__details .bannersection--download-save-2800 .bannersection__bannersection--download-save-2800-col1 {
			@include above-phone {
				background-color: #418fde;
			}
		}
		.location__details .bannersection--download-save-2800 .bannersection__bannersection--download-save-2800-col3 {
			@include above-phone {
				background-color: #0047bb;
			}
		}
		.nearby-locations .nearby-location-item {
			@include above-phone {
				display: grid; 
				grid-template-columns: 1fr 1fr; 
				grid-template-rows: 1fr; 
				gap: 0px 30px; 
				grid-template-areas: 
					". ."; 
				height: 100%; 
			}
		}
	}
	.wider-wheels-sites {
		h3 {
			margin-top: 35px !important;
			margin-bottom: 5px !important;
			a {
				color:black !important;
				text-decoration: none !important;
				font-size: 2rem;
			}
		}
		p {
			a {
				&:hover {
					text-decoration: none !important;
				}
			}
		}
	}
}