/* ==========================================================================
	GLOBAL
========================================================================== */

* {
	margin: 0;
	padding: 0;
}

html, body {
	height: 100%;
}

html {
	font-size: 62.5%;
	min-height: 100%;
	overflow-x: hidden;
}

body {
  background: $white;
  color: $black;
	font-family: $openSans;
	min-height: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-overflow-scrolling: touch;
}

a {
	text-decoration: none;
	@include transition(color, 0.25s);
}

.body-restrain {
	overflow: hidden;
}

.main {
	background: $white;
	min-height: calc(100% - 377px);
	opacity: 1;
	transition: transform $transitionSpeed ease-out, opacity $transitionSpeed ease-out;

	@include above-phone {
		min-height: calc(100% - 289px);
	}

	@include desktop {
		min-height: calc(100% - 237px);
	}

	@include large-desktop {
		min-height: calc(100% - 203px);
  }

  &--offwhite {
    background: $navigationBg;
  }

	.body-restrain & {
		opacity: 0.5;
		transform: translateX(-270px);

		@include above-phone {
			transform: translateX(-320px);
		}
	}
}

.wrapper {
  max-width: $tablet;
  margin: auto;
  padding: 0 16px;
  width: 100%;

  @include above-phone {
    padding: 0;
  }
}

.section {
	@extend %wysiwyg;
	margin: 0 auto;
	max-width: $content;
	padding: 30px 0;

	@include above-phone {
		padding: 50px 0;
  }

  &--white {
    background: $white;
  }

  &--offwhite {
    background: $offwhite;
  }

  &--narrow {
    max-width: $tabletPortrait;
  }

	&--minimised {
		padding-top: 0;
  }

  &--no-padding-top {
    padding-top: 0;
  }
  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--bordered {
    position: relative;
    width: 100%;
    max-width: 100%;

    &:before {
      border-top: solid 1px $hr;
      content: '';
      display: block;
      height: 0;
      left: 15px;
      position: absolute;
      width: calc(100% - 30px);
      top: 0;
    }
  }

}

[data-whatintent="mouse"] *:focus {
  outline: none;
}

html:not([data-whatintent="keyboard"]):focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'co-headline-bold', 'Open Sans', arial, sans-serif;
}