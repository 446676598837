/* ==========================================================================
	WASH CLUB
========================================================================== */

.banner--wash-club {
	background-color: #fff;
	.wash-club-logo {
		background: url('/assets/images/newstyle/imo-new-logo.png') no-repeat center center;
		background-size: 220px auto;
		width: 250px;
		//height: 190px;
		display: block;
		margin: 150px auto 70px;
	}
}
.wash-club-panel-wrap {
	width: 100%;
    z-index: 35;
    padding: 0;
    max-width: 1170px;
    margin: auto;
    @include above-phone-device {
        padding: 0 15px 0 15px;
    }
    @include above-tablet-portrait {
        padding: 0 40px 40px 40px;
        display: grid;
        grid-template-areas:
        'a a a'
        'b c d'
        'e e f';
        align-content: space-between;
        grid-gap: 15px;
    }
}
.wash-club-panel {
	background: rgba($black, 0.75);
	box-shadow: 0px 0px 7px 0px rgba($black, 0.25);
	margin: 20px 0;
	max-width: 100%;
	padding: 20px;
    text-align: center;
	@include above-tablet-portrait {
        width: 100%;
		max-width: 1170px;
        padding: 30px 20px;
        grid-area: a;
        margin: 0;
        &:nth-child(5) {
            grid-area: e;
        }
        &:nth-child(6) {
            grid-area: f;
        }
    }
    
    img {
        margin: 0 auto 20px auto;
        display: block;
    }
    p {
        text-align: center;
        font-family: $openSans;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.18px;
        color: $white;
        opacity: 0.75;
    }
    span {
        img {
            padding: 0 10px;
            margin: 0;
            display: inline-block;
            transform: translate(0px, 4px);
        }
    }
    a {
        border: none;
        border-radius: 0;
        display: inline-block;
        margin: auto;
        font-family: $openSans;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: 700;
        height: 42px;
        background: #001E60;
        color: #fff;
        padding: 5px 15px;
        -webkit-transition: background .4s,color .4s;
        -o-transition: background .4s,color .4s;
        transition: background .4s,color .4s;
        text-align: center;
        @include above-tablet-portrait {
            padding: 5px 30px;
        }
        &:hover {
            background: #fff;
            color: #001E60;
            cursor: pointer;
        }
    }
    h2 {
        @include above-tablet-portrait {
            font-size: 2.7rem;
            line-height: 3.7rem;
            letter-spacing: 0px;
        }
        &.wash-club-panel__subtitle {
            @include above-tablet-portrait {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }
    }
}

.wash-club-panel--small {
	.wash-club-panel__present {
		width: 124px;
		height: auto;
	}
    @include above-tablet-portrait {
        width: 100%;
        max-width: 1170px;
        padding: 30px 20px;
        grid-area: b;
        &:nth-child(3) {
            grid-area: c;
        }
        &:nth-child(4) {
            grid-area: d;
        }
    }
}

.wash-club-panel--large {
    @include above-tablet-portrait {
        display: grid;
        grid-template-areas:
        'a b b b';
        justify-content: center;
        align-items: center;
        img {
            grid-area: a;
            padding: 0 40px;
            margin: 0;
        }
        h2 {
            grid-area: b;
            text-align: left;
        }
    }
}

.wash-club-panel--card {
    @include above-tablet-portrait {
        padding-left: 40%;
        text-align: left;
        background: rgba($black, 0.75) url('/assets/images/newstyle/card-hand.png') no-repeat left bottom;
        background-size: 450px auto;
        h2 {
            text-align: left;
        }
    }
}

.wash-club-panel__title {
	@extend %heading;
	@extend %heading--tertiary;
	color: $white;
	font-size: 2rem;
	letter-spacing: 2.3px;
	line-height: 1.2;
	margin-bottom: 15px;
	text: {
		align: center;
		shadow: 0px 0px 7px rgba($black, 0.25);
	}

	@include above-phone {
		margin-bottom: 25px;
	}
}

.wash-club-panel__subtitle {
	@extend %heading;
	@extend %heading--tertiary;
	color: $white;
	font-size: 1.6rem;
	letter-spacing: 0px;
	line-height: 1.2;
	margin-bottom: 15px;
	text: {
		align: center;
		shadow: 0px 0px 7px rgba($black, 0.25);
	}

	@include above-phone {
		margin-bottom: 25px;
	}
}

.wash-club-panel__form {
	position: relative;
	width: 100%;
	@include clearfix;
}

.wash-club-panel__field {
	@extend %icon--point-filled;
	margin-bottom: 15px;
	position: relative;
	width: 100%;

	@include above-phone {
		float: left;
		margin: {
			bottom: 0;
			right: 20px;
		}
		width: calc(68% - 20px);
	}

	&:before {
		color: $icons;
		font-size: 2rem;
		left: 10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&--loader {
		&:after {
			background: url('/assets/images/ajax-loader-white-bg.gif') right center no-repeat;
			content: '';
			display: inline-block;
			height: 16px;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			width: 16px;
		}
	}
}

.wash-club-panel__item {
	border: none;
	border-radius: 0;
	display: block;
	font: {
		family: $openSans;
		size: 1.4rem;
		weight: 700;
	}
	height: 42px;

	@include above-phone {
		font-size: 1.4rem;
	}

	&--field {
		background: $white;
		color: $field;
		padding: 15px 15px 15px 40px;
		width: 100%;

		&::placeholder {
			color: $field;
		}
	}

	&--submit {
		background: $button;
		color: $white;
		padding: 5px 15px;
		// Leaving commented until fully tested
		//text-transform: capitalize;
		transition: background $menuSpeed, color $menuSpeed;
		width: 100%;

		@include above-phone {
			float: left;
			width: 32%;
		}

		&:hover {
			background: $white;
			color: $button;
		}
	}
}

.wash-club-panel__predictive-output {
	background: $white;
	left: 0;
	position: absolute;
	top: 43px;
	width: 100%;

	@include above-phone {
		width: calc(68% - 20px);
	}
}

.wash-club-panel__predictive-output-item {
	color: $field;
	cursor: pointer;
	display: block;
	font: {
		size: 1.6rem;
		weight: 600;
	}
	padding: 15px;

	&:hover {
		background: $field;
		color: $white;
	}
}