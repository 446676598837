.signup-form input[type="checkbox"] {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    width: 18px;
    height: 18px;
}

#mergeRow-gdpr {
    margin-top: 20px;
}

#mergeRow-gdpr fieldset label {
    font-weight: normal;
}

.signup-form {
    @include respond-to($tabletPortrait) {
        margin-left: 9%;
        max-width: 83%;
    }
}

.signup-form .mc_fieldset {
    border: none;
    min-height: 0px;
    padding-bottom: 0px;
}

.grid.newsletter-signup {
    margin-bottom: 2%;
}

#form-part-left {
    text-align: right;
    display: inline-block;
    color: white;
}

.newsletter_signup {
    text-align: center;
    color: #007ac3;
    font: 14px Helvetica, Arial, sans-serif;
}

.newsletter_signup_intro {
    text-align: center;
    display: inline-block;
}

.newsletter_signup_intro h2 {
    margin-bottom: 10px;
}

.newsletter_signup_logo {
    text-align: center;
    width: 160px;
    margin: 10px auto 15px;
}

.signup-button {
    max-width: 150px;
    margin-top: 0;
}

.content__gdprBlock p {
    font-size: 10px;
    display: inline;
}

.content__gdprBlock p a {
    color: white;
    text-decoration: underline;
}

.newsletter-signup {
    font-size: 1.3em;
}

#mce-EMAIL {
    color: #007ac3;
}

.newsletter-signup .white-dividing-line {
    margin-top: 1%;
    height: 80%;
    border-left: 1px solid white;
    float: left;
    margin-left: -3.2%;
    @include respond-to($tabletPortrait) {
        height: 0px;
        margin-top: -2%;
        margin-bottom: 1%;
        margin-left: 18%;
        width: 60%;
        border-top: 1px solid white;
        border-left: none;
    }
    @include respond-from($respPromo) {
        margin-left: -3.2%;
    }
    @include respond-from($tabletLandscape) {
        margin-left: -2.2%;
    }
    @include respond-from($resp1500) {
        margin-left: -1.8%;
    }
    @include respond-from($largerDesktop) {
        margin-left: -1.6%;
    }
    @include respond-from($resp2130) {
        margin-left: -1.2%;
    }
}

#form-part-left ul {
    @include respond-from($resp600) {
        max-width: 100%;
        text-align: center;
    }
    @include respond-from($respPromo) {
        margin-left: 16%;
    }
    @include respond-from($respHeader) {
        max-width: none;
        margin-left: 34%;
        text-align: center;
    }
    @include respond-from($largerDesktop) {
        max-width: 37%;
        margin-left: 62%;
    }
    @include respond-from($resp2130) {
        margin-left: 74%;
    }
}

#form-part-left li {
    list-style-type: none;
    text-align: left;
    @include respond-to($tabletPortrait) {
        text-align: left;
    }
}

.signup-form-inner {
    @include respond-to($tabletPortrait) {
        max-width: 100%;
    }
    @include respond-from($largerDesktop) {
        max-width: 43%;
    }
}

.newsletter-signup h2 {
    margin-bottom: 10px;
    @include respond-to($tabletPortrait) {
        text-align: center;
    }
}

#form-part-right {
    text-align: left;
    display: inline-block;
    color: white;
    @include respond-to($tabletPortrait) {
        // text-align: center;
    }
}

#form-part-right .email {
    width: 50%;
    margin-top: 3px;
    display: block;
    @include respond-to($tabletPortrait) {
        width: 100%;
        // text-align: center;
        // margin-left: 25%;
    }
    @include respond-from($tabletPortrait) {
        max-width: 50%;
    }
    @include respond-from($largerDesktop) {
        width: 70%;
        max-width: 70%;
    }
}

.signup-button-container {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 8px;
    @include respond-to($tabletPortrait) {
        text-align: right;
    }
}

.newsletter-signup .background-color-imoblue {
    background-color: #007ac3;
}

.newsletter-signup .signup-gdpr-content {
    display: inline-block;
    margin-top: -1%;
    @include respond-from($tabletPortrait) {
        max-width: 70%;
    }
    @include respond-from($resp1500) {
        max-width: 40%;
    }
    @include respond-from($largerDesktop) {
        max-width: 60%;
    }
    @include respond-from($resp2130) {
        max-width: 66%;
    }
}

.newsletter-signup fieldset {
    margin: 0px;
    padding: 0px;
}

// Checkbox Color Change (Tick)
.check-item {
    display: block;
    position: relative;
    padding-left: 35px;
}

.check-item input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
}

.check-item:hover input~.checkmark {
    background-color: #ccc;
}

.check-item input:checked~.checkmark {
    background-color: #eee;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-item input:checked~.checkmark:after {
    display: block;
}

.check-item .checkmark:after {
    left: 4px;
    top: -4px;
    width: 9px;
    height: 20px;
    border: solid $brandPrimary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(33deg);
    -ms-transform: rotate(33deg);
    transform: rotate(33deg);
}

.checklist-container {
    text-align: center;
}

.asterisk {
    color: $brandPrimary;
}

#was-crm-gdpr-label a {
    color: $brandPrimary;
}

.newsletter-signup {
    margin-bottom: 0 !important
}

.newsletter_signup_blue {
    background-color: #418FDE !important;
}
#contacta-webform-submission-608b737437e3445d32975371 {
    background-color: #001E60 !important;
}
@media only screen and (min-width: 981px) {
    .newsletter_signup_blue .newsletter-form-container,
    .newsletter_signup_blue .newsletter_signup_intro {
        max-width: 980px;
        margin: auto
    }
}

@media only screen and (min-width: 981px) {
    .newsletter_signup_blue form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: 20px 20px
    }
    .newsletter_signup_blue form .grid-email {
        grid-area: 1 / 3 / 2 / 5
    }
    .newsletter_signup_blue form .grid-last-name {
        grid-area: 1 / 2 / 2 / 3
    }
    .newsletter_signup_blue form .grid-first-name {
        grid-area: 1 / 1 / 2 / 2
    }
    .newsletter_signup_blue form .grid-gender {
        grid-area: 2 / 1 / 3 / 2
    }
    .newsletter_signup_blue form .grid-cars {
        grid-area: 2 / 2 / 3 / 3
    }
    .newsletter_signup_blue form .grid-type-car {
        grid-area: 2 / 3 / 3 / 4
    }
    .newsletter_signup_blue form .grid-cars-imo {
        grid-area: 2 / 3 / 3 / 5
    }
    .newsletter_signup_blue form .grid-confirm {
        grid-area: 3 / 1 / 4 / 5
    }
    .newsletter_signup_blue form .grid-confirm .grid-confirm-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas: "confirm-cell confirm-cell confirm-cell confirm-cell confirm-cell subscribe-cell" "required-cell required-cell required-cell required-cell required-cell required-cell"
    }
    .newsletter_signup_blue form .grid-confirm .confirm-cell {
        grid-area: confirm-cell
    }
    .newsletter_signup_blue form .grid-confirm .subscribe-cell {
        grid-area: subscribe-cell
    }
    .newsletter_signup_blue form .grid-confirm .required-cell {
        grid-area: required-cell
    }
    .newsletter_signup_blue form .grid-cars-imo-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 20px 20px;
        grid-template-areas: "grid-cars-imo-type grid-cars-imo-use"
    }
    .newsletter_signup_blue form .grid-cars-imo-wrap .grid-cars-imo-type {
        grid-area: grid-cars-imo-type
    }
    .newsletter_signup_blue form .grid-cars-imo-wrap .grid-cars-imo-use {
        grid-area: grid-cars-imo-use
    }
    .newsletter_signup_blue form .grid-cars-imo-wrap .grid-cars-imo-use div {
        width: 49%;
        display: inline-block
    }
}

.newsletter_signup_blue .newsletter_signup_intro img {
    margin-bottom: 30px
}
.newsletter_signup_blue h2{
    padding-top:5%;
}
.newsletter_signup_blue h2,
.newsletter_signup_blue p {
    color: #fff;
    text-align: left
}

@media only screen and (min-width: 981px) {
    .newsletter_signup_blue h2 {
        font-size: 32px
    }
}

.newsletter_signup_blue p,
.newsletter_signup_blue span {
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.4;
    display: block
}

.newsletter_signup_blue label {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 5px
}

.newsletter_signup_blue label a {
    -webkit-transition: color .4s;
    -o-transition: color .4s;
    transition: color .4s
}

.newsletter_signup_blue label a:hover {
    -webkit-transition: color .4s;
    -o-transition: color .4s;
    transition: color .4s;
    color: #fff !important
}

.newsletter_signup_blue input,
.newsletter_signup_blue select {
    border: 1px solid #767676;
    width: 100%;
    min-height: 40px;
    color: #767676;
    padding: 0 10px
}

.newsletter_signup_blue input[type='radio'],
.newsletter_signup_blue select[type='radio'] {
    width: 18px;
    height: 18px;
    min-height: 18px;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #767676
}

.newsletter_signup_blue input[type='checkbox'],
.newsletter_signup_blue select[type='checkbox'] {
    width: 18px;
    height: 18px;
    min-height: 18px;
    margin-right: 10px;
    border: 1px solid #767676
}

.newsletter_signup_blue input[type='submit'],
.newsletter_signup_blue select[type='submit'] {
    letter-spacing: 2px;
    line-height: 1.25;
    padding: 0 20px;
    background-color: #f38200;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: background-color .4s, color .4s;
    -o-transition: background-color .4s, color .4s;
    transition: background-color .4s, color .4s
}

@media only screen and (min-width: 981px) {
    .newsletter_signup_blue input[type='submit'],
    .newsletter_signup_blue select[type='submit'] {
        padding: 20px
    }
}

.newsletter_signup_blue input[type='submit']:hover,
.newsletter_signup_blue select[type='submit']:hover {
    color: #f38200;
    background-color: #fff;
    -webkit-transition: background-color .4s, color .4s;
    -o-transition: background-color .4s, color .4s;
    transition: background-color .4s, color .4s
}

.newsletter_signup_blue .newsletter-form-input-wrap {
    margin-bottom: 20px
}

.newsletter_signup_blue .newsletter-form-input-wrap-radio,
.newsletter_signup_blue .newsletter-form-input-wrap-checkbox {
    margin-bottom: 20px
}

.newsletter_signup_blue .newsletter-form-input-wrap-radio div,
.newsletter_signup_blue .newsletter-form-input-wrap-checkbox div {
    padding: 10px 10px 10px 0
}

.newsletter_signup_blue .newsletter-form-input-wrap-radio div:last-child,
.newsletter_signup_blue .newsletter-form-input-wrap-checkbox div:last-child {
    padding: 10px 0
}

.newsletter_signup_blue .newsletter-form-input-wrap-radio label,
.newsletter_signup_blue .newsletter-form-input-wrap-checkbox label {
    width: 85%;
    display: inline-block;
    vertical-align: top
}

@media only screen and (min-width: 981px) {
    .newsletter_signup_blue .newsletter-form-input-wrap-radio label {
        width: 65%
    }
}

@media only screen and (min-width: 981px) {
    .newsletter_signup_blue .newsletter-form-input-wrap-checkbox label {
        width: 90%
    }
}

.newsletter_signup_blue .newsletter-required-text {
    padding: 20px 0
}

.newsletter-form-input-wrap.grid-confirm {
    margin-bottom: 0px;
}

main.main {
    min-height:auto;
}

.contacta-webform-form {
    max-width: 1010px;
    margin: auto;
}
/* Homepage Newsletter signup */
.newsletter-signup-home {
    display: grid; 
    grid-template-columns: auto 1fr auto; 
    grid-template-rows: 1fr; 
    gap: 0px; 
    grid-template-areas: 
        ". . ."
        ". . ."; 
    height: 100%;
    background-color: #001e60;
    width: 100%;
    border-bottom: 1px solid #fff;
}
.newsletter-signup-form {
    width: 740px;
    margin: auto;
    padding: 40px 15px;
    @media only screen and (max-width: 550px) {
        width: 100%;
    }
    .error-msg {
        position: absolute;
    }
}
.newsletter-signup-home .email-holder {
    margin-right: 20px;
}
.newsletter-signup-home h2 {
    color: #ffffff;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 30px;
    font-family: "co-headline-regular","Open Sans",Arial,sans-serif;
    font-weight: normal;
    @media only screen and (max-width: 550px) {
        font-size: 18px;
        line-height: 23px;
    }
}
#contacta-webform-table-61e58767509583314b51ba16 {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 0fr 3.5fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        ". . ."; 
    height: 100%;
    align-items: end; 
    @media only screen and (max-width: 550px) {
        display: block;
    }
}
#CONTACTA_61e58767509583314b51ba16 {
    display: block;
    width: 100%;
}
#CONTACTA_61e58767509583314b51ba16 label {
    color: #ffffff;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 15px;
    line-height: 23px;
    font-family: "co-headline-regular","Open Sans",Arial,sans-serif;
    display: block;
}
#CONTACTA_61e58767509583314b51ba16 .contacta-webform-table .contacta-col {
    padding: 0;
    @media only screen and (max-width: 550px) {
        padding: 0 0 20px 0;
    }
}
#CONTACTA_61e58767509583314b51ba16 .contacta-webform-table input:not([type="radio"]):not(.hasDatepicker):not([type="checkbox"]) {
    padding: 10px;
    font-size: 18px;
    @media only screen and (max-width: 550px) {
        width: 100%;
    }
}
.newsletter-signup .email-holder {
    margin-right: 20px;
    @media only screen and (max-width: 550px) {
        margin-right: 0;
    }
}
#contacta-webform-submission-61e58767509583314b51ba16 {
    background: none !important;
    border: solid 1px #fff !important;
    border-radius: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    line-height: 44px !important;
    @media only screen and (max-width: 550px) {
        width: 100% !important;
    }
}
#contacta-webform-submission-61e58767509583314b51ba16:hover {
    background: #fff !important;
    border: solid 1px #4a4f55 !important;
    color: #4a4f55 !important;
    filter: none !important;
}