/* ==========================================================================
	HOME SEARCH
========================================================================== */

.home-search-panel {
	left: 50%;
	margin: 52px 0;
	max-width: 100%;
	padding: 20px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 570px;
	z-index: 35;
	@include above-phone {
		background: $brandPrimary;
		box-shadow: 0px 0px 7px 0px rgba($black, 0.25);
		max-width: calc(100% - 20px);
		padding: 80px 40px 60px;
		left: 7%;
		right: initial;
		transform: translate(0, -50%);
	}
	h2, h3 {
		text-align: center;
    font-family: 'co-headline-light', Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 3px;
		color: $white;
	}
	h2 {
		font-size: 30px;
		margin-bottom: 25px;
	}
	h3 {
		font-size: 24px;
		font-weight: 400;
		margin-bottom: 20px;
	}
	@include large-desktop {
		width: 35%;
		max-width: 666px;
		h2 {
			font-size: 56px;
			margin-bottom: 30px;
		}
		h3 {
			font-size: 38px;
			font-weight: 400;
			margin: 0px auto 35px;
			max-width: 375px;
		}
	}
}

.home-search-panel__title {
	@extend %heading;
	@extend %heading--tertiary;
	color: $white;
	font-size: 2rem;
	letter-spacing: 2.3px;
	line-height: 1.2;
	margin-bottom: 15px;
	text: {
		align: center;
		shadow: 0px 0px 7px rgba($black, 0.25);
	}

	@include above-phone {
		margin-bottom: 25px;
	}
}

.home-search-panel__form {
	position: relative;
	width: 100%;
	@include clearfix;
}

.home-search-panel__field {
	@extend %icon--point-filled;
	margin-bottom: 15px;
	position: relative;
	width: 100%;

	@include above-phone {
		float: left;
		margin: {
			bottom: 0;
			right: 20px;
		}
		width: calc(68% - 20px);
	}

	&:before {
		color: $icons;
		font-size: 2rem;
		left: 10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&--loader {
		&:after {
			background: url('/assets/images/ajax-loader-white-bg.gif') right center no-repeat;
			content: '';
			display: inline-block;
			height: 16px;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			width: 16px;
		}
	}
}

.home-search-panel__item {
	border: none;
	border-radius: 20px;
	display: block;
	font: {
		family: $openSans;
		size: 1.4rem;
		weight: 700;
	}
	height: 42px;

	@include above-phone {
		font-size: 1.4rem;
	}

	@include large-desktop {
		height: 40px;
		font: {
			size: 1.8rem;
		}
	}

	&--field {
		background: $white;
		color: $field;
		padding: 15px 15px 15px 40px;
		width: 100%;
		@include large-desktop {
			padding: 20px 20px 20px 40px;
		}
		&::placeholder {
			color: $field;
		}
	}

	&--submit {
		background: $brandTertiary;
		color: $white;
		padding: 5px 15px;
		// Leaving commented until fully tested
		//text-transform: capitalize;
		transition: background $menuSpeed, color $menuSpeed;
		width: 100%;

		@include above-phone {
			float: left;
			width: 32%;
		}


		&:hover {
			background: $white;
			color: $button;
		}
	}
}

.home-search-panel__predictive-output {
	background: $white;
	left: 0;
	position: absolute;
	top: 43px;
	width: 100%;

	@include above-phone {
		width: calc(68% - 20px);
	}
}

.home-search-panel__predictive-output-item {
	color: $field;
	cursor: pointer;
	display: block;
	font: {
		size: 1.6rem;
		weight: 600;
	}
	padding: 15px;

	&:hover {
		background: $field;
		color: $white;
	}
}
.country-dk {
	.product-callout__price span {
		color: #fff;
	}
}