/* ==========================================================================
	DROPDOWN
========================================================================== */

.dropdown {
	position: relative;
}

.dropdown__menu {
	position: absolute;
	top: 100%;
}

.dropdown__menu {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;

	.dropdown--open & {
		display: block;
	}
}