/* ==========================================================================
	GRID
========================================================================== */

.grid {
  align: {
    items: stretch;
    self: flex-start;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;

  &--allow-breakout {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }

  &--bordered {
    justify-content: flex-start;
    margin: 25px 0;
    padding-top: 20px;
    position: relative;

    &:before {
      border-top: solid 1px $hr;
      content: '';
      display: block;
      height: 0;
      left: 15px;
      position: absolute;
      width: calc(100% - 30px);
      top: 0;
    }
  }

  &--spaced {
    padding-top: 20px;
  }

  @include desktop {
    &--nowrap {
      flex-wrap: nowrap;
    }
  }

  &--nowrap-all {
    flex-wrap: nowrap;
  }

  &--form {
    @extend .grid--bordered;
    @extend .grid--spaced;
    justify-content: flex-start;

    @include above-phone {
      $total: 13;
      $i: 1;
      @while $i < $total {
        .grid__item--columns-#{$i} {
          max-width: 8.3333% * $i;
        }
        $i: $i + 1;
      }
    }
  }

  &--outer {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }
}

.grid__item {
  display: flex;
  flex: 1;
  overflow: visible;
  position: relative;
  flex: 1 1 100%;
  width: 100%;

  &--no-internal-grid {
    display: block;
  }

  &--padded {
    padding: 15px;

    .grid__item--form-row-container & {
      padding: 0 15px 15px;
    }
  }

  &--extra-padded {
    padding: 40px;
    @include above-phone {
       padding: 40px 120px;
    }

  }
  &--padded-service {
    padding: 20px 15px;
  }

  &--form-row-container {
    padding-bottom: 30px;
    position: relative;
  }

  @include above-small-phone {
    &--columns-2 {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }

  @include above-phone {
    $total: 13;
    $i: 1;
    @while $i < $total {
      &--columns-#{$i} {
        flex: 1 1 8.3333%*$i;
      }
      $i: $i + 1;
    }

    @include desktop {
      &--columns-3 {
        flex: 1 1 25%;
      }
    }

    &--columns-2 {
      max-width: 33.3333%;

      @include desktop {
        flex: 1 1 16.6666%;
      }
    }
  }

  $total: 31;
  $i: 1;
  @while $i < $total {
    &:nth-child(#{$total - $i}) {
      z-index: $i;
    }
    $i: $i + 1;
  }

  &.grid__item--on-top {
    z-index: 31;
  }

  &.grid__item--predictive {
    z-index: 32;
  }

  &--full-column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &--submit {
    @extend .grid__item--no-internal-grid;

    @include respond-from($respHeader + 1) {
      margin-top: 0;
    }
  }
}

.grid-row {
  @extend .grid;
  flex-wrap: nowrap;
}
