/* ==========================================================================
  TYPOGRAPHY
========================================================================== */

.heading {
  @extend %heading;
}

.heading--primary {
  @extend %heading--primary;
}

.heading--secondary {
  @extend %heading--secondary;
}

.heading--tertiary {
  @extend %heading--tertiary;
}

.heading--banner {
  color: $white;
  display: block;
  left: 50%;
  margin-top: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 3;
  text-shadow: 0px 1px 1px rgba(150, 150, 150, 1);
  &:last-child {
    margin-top: 50px;
  }
}

.text {
  @extend %text;
}
.text--bold {
  @extend %text;
  color: $heading;
  font-weight: 700;
}
.text__highlight {
  color: $brandPrimary;
}

.text__centered {
  text-align: center;
}

.wysiwyg {
  @extend %wysiwyg;
}

.heading-eyebrow {
  @extend %heading-eyebrow;
}

.heading-eyebrow--subtitle {
  @extend %heading-eyebrow;
  @extend %heading-eyebrow--subtitle;
}

.feature-title {
  @extend %feature-title;
}

.feature-text {
  @extend %feature-text;
}

.label-title {
  @extend %label-title;
}
