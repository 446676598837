.location__details {
  
  .nearby-location-item {
    @include respond-to($tabletPortrait){
      display: block;
    }

  }

  .nearby-location-item div {
    position: relative;
    padding-bottom: 62px;

    h3 {
      margin: 20px;
    }

    p {
      display: block;
      margin: 0 20px 20px 20px;
    }

    a {
      @extend .button;
      display: inline-block;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      margin: 0 20px 20px 20px;
    }

  }

  .slick-prev {
    color: $white;
    left: 0;
    z-index: 50;

    &::before {
      @extend %icon--arrow-left-before;
      font-size: 38px;
      color: $white;
    }
  }

  .slick-next {
    color: $white;
    right: 10px;
    z-index: 50;

    &::before {
      @extend %icon--arrow-right-before;
      font-size: 38px;
      color: $white;
    }
  }

  .testimonal-slide {
    position: relative;

    &::before {
      background: rgba($black, 0.5);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &-image {
      z-index: 1;
    }

    &-text {
      color: $white !important;
      width: calc(100% - 80px) !important;
      z-index: 3 !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;

      .testimonial {
        color: $white;
      }
    }
  }


  .app-store-button {
    @extend .button;
    @extend .button--inverse-solid;
    display: inline-block;
    margin: 0 10px;
    border-radius: 3px;
    width: calc(50% - 22px);
  }

  .grid {
    &:last-child {
      .grid__item:nth-child(2) {
        display: block;
      }
    }
  }
}

.home-testimonials {
  .testimonal-slide {
    position: relative;

    &::before {
      background: rgba($black, 0.5);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &-image {
      z-index: 1;
    }

    &-text {
      color: $white !important;
      width: calc(100% - 80px) !important;
      z-index: 3 !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;

      .testimonial {
        color: $white;
      }
    }
  }
}