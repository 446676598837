/* ==========================================================================
	HEADER
========================================================================== */

.header {
  // background: rgba($black, 0.5);
  background: $white;
  left: 0;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: $zHeader;
  transition: background $menuSpeed;

  @include tablet-portrait {
    background: transparent;
  }

  &--scrolled,
  &--map,
  &--stuck {
    background: rgba($black, 0.5);

    .header__main>.header__logo-link {
      width: 148px;
      @include tablet-portrait {
        width: 200px;
      }
    }
  }
  fieldset {
    padding: 0;
    margin: 0;
  }
}

.header__main {
  padding: 0 10px;
  position: relative;
  background-color: $white;
  @include clearfix;

  @include large-desktop {
    padding: 0 30px;
  }

  // @include large-desktop {
  //   padding: 0 60px;
  //   height: 150px;
  //   @include transition(height, $transitionSpeed);
  //   &.header--scrolled {
  //     height: initial;
  //     @include transition(height, $transitionSpeed);
  //   }
  // }

  &--location {

    // Side Map Pointer
    .feature-button--pointer {
      display: block;
    }

    // Side Search
    .feature-button--search {
      display: none;
    }
  }
}

@include large-desktop {
  .header--scrolled {
    .header__main {
      height: initial;
    }
  }
}

// Top Bar
.header__bar {
  background: $brandPrimary;
  display: block;
  list-style: none;
  margin: 0;
  padding: 7px 0;
  position: relative;
  text-align: right;
  width: 100%;
  @include clearfix;

  @include tablet-portrait {
    padding: 7px 10px;
  }
}

.header__bar-item {
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 0 10px;
  vertical-align: top;

  &:last-child {
    padding-left: 0;

    @include above-phone {
      padding: 0;
    }

    .header__bar-link:before {
      margin: 0;
    }
  }

  &--left {
    float: left;
    font-size: 0;
    margin: 0;
  }

  @include tablet-portrait {
    letter-spacing: 2px;
    margin: 0;
    padding: 0 20px
  }
}

.header__bar-link {
  color: $white;
  display: block;

  &:before {
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 8px;
    vertical-align: middle;

    @include tablet-portrait {
      margin-right: 8px;
    }
  }

  &--basket {
    @extend %icon--basket;

    @include above-phone {
      font-size: 1rem;
    }

    &:after {
      background: $brandPrimary;
      color: $white;
      content: attr(data-notification);
      cursor: default;
      font-size: 1.5rem;
      opacity: 0;
      padding: 5px 20px 5px 10px;
      pointer-events: none;
      position: absolute;
      right: 0;
      // Leaving commented until fully tested
      //text-transform: capitalize;
      top: calc(100% + 2px);
      z-index: 999;
      @include transition(opacity, $transitionSpeed);
      @include transition-delay(0.1s);
    }
  }

  &--basket-active {
    &:after {
      opacity: 1;
    }
  }

  &--account {
    @extend %icon--account;
  }

  @include tablet-portrait {
    transition: color $menuSpeed;

    &:hover {
      color: $white;
    }
  }
}

// Site Logo
.header__logo-link {
  display: block;
  margin: 0;
  padding: 10px 0;
  width: 148px;
  @include above-phone {
    width: 260px;
  }
  // @include tablet-portrait {
  //   padding: 15px 0;
  //   width: 148px;
  // }
}

.header__logo-link--menu {
  margin: 0;
  padding: 0;
  width: 140px;
}

.header__logo-image {
  display: block;
  width: 100%;
}

// Menu Buttons
.feature-button {
  background: transparent;
  border: none;
  display: block;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  transition: color $menuSpeed;

  &:before {
    color: $brandPrimary;
    font-size: 3.8rem;
  }

  &--login {
    right: 5%;
    color: $white;
    background: $brandPrimary;
    padding: 10px;
    width: 80px;
    height: 40px;
    text-align: center;
    transition: none;
    font-size: 14px;

    @include phone {
      padding: 5px;
      width: 70px;
      height: 30px;
      right: 15%;
    }
  }

  &--menu {
    height: 36px;
    right: 5px;
    width: 45px;
    z-index: $zHeader + 1;

    @include tablet-portrait {
      right: 25px;
    }
  }

  &--pointer {
    @extend %icon--point;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    display: none;

    &:before {
      margin-left: -4px;
    }
  }

  &--search {
    @extend %icon--search;
    display: block;
  }

  &--active {
    color: $headerHover;
  }

  &--close {
    left: 5px;
    right: auto;

    .search-results-wrapper & {
      display: none;

      @include above-phone {
        display: inline-block;
      }
    }

    @include above-phone {
      left: 25px;
    }
  }

  &--top-right {
    background: $white;
    height: 45px;
    left: auto;
    right: 0;
    top: 0;
    transform: translateY(0%);
    transition: transform $menuSpeed;
    z-index: 1;

    .feature-button__menu-lines {
      top: 21px;

      &:after,
      &:before {
        background: $navigation;
      }
    }

    &:hover {
      .feature-button__menu-lines {

        &:after,
        &:before {
          background: $headerHover;
        }
      }
    }
  }

  &--top-right-active {
    transform: translateY(-100%);
  }

  @include tablet-portrait {
    &:before {
      transition: color $menuSpeed;
    }

    &:hover {
      &:before {
        color: $headerHover;
      }

      .feature-button__menu-lines,
      .feature-button__menu-lines:after,
      .feature-button__menu-lines:before {
        background: $headerHover;
      }

      .feature-button__menu-lines {

        .header--active &,
        .header__search--active & {
          background: transparent;
        }
      }
    }
  }
}

.feature-button__menu-lines,
.feature-button__menu-lines:after,
.feature-button__menu-lines:before {
  background: $brandPrimary;
  content: "";
  cursor: pointer;
  display: block;
  height: 2px;
  position: absolute;
  transform: rotate(0deg);
  transition: all $transitionSpeed ease;
  width: 35px;
}

.feature-button__menu-lines {
  left: 5px;
  top: 17px;

  &:before {
    top: -12px;
  }

  &:after {
    bottom: -12px;
  }
}

@mixin mobile-menu-lines {
  background-color: transparent !important;

  &:after,
  &:before {
    top: 0;
  }

  &:before {
    transform: rotate(45deg) !important;
  }

  &:after {
    transform: rotate(-45deg) !important;
  }
}

.no-js .feature-button--menu {
  cursor: default;

  &:hover {
    visibility: hidden;

    .feature-button__menu-lines {
      @include mobile-menu-lines;
    }

    &~.feature-button__menu {
      transform: translate3d(-100%, 0%, 0);
    }
  }
}

.header--active .feature-button__menu-lines,
.feature-button--close .feature-button__menu-lines {
  @include mobile-menu-lines;

  &:after,
  &:before {
    background: $uiControls;
  }
}

// Main Menu
.header__menu {
  background: $white;
  border: solid 1px $uiBorder;
  border-right: none;
  bottom: 0;
  height: 100%;
  left: 100%;
  max-width: 355px;
  overflow: auto;
  padding: 25px 30px;
  position: fixed;
  transform: translate3d(0%, 0%, 0);
  transition: transform $transitionSpeed ease-out;
  width: calc(100% - 50px);
  z-index: $zHeader - 1;
  @include above-phone {
    max-width: 405px;
  }
  .no-js & {
    z-index: $zHeader + 2;
  }

  .header__bar~.header__main & {
    height: calc(100% - 31px);
  }

  &:hover,
  .header--active & {
    transform: translate3d(-100%, 0%, 0);
  }
}

.header__menu-list {
  display: block;
  list-style: none;
  margin: 30px 0;
  min-height: calc(100% - 169px);
  padding: 0;
}

.header__menu-item {
  margin: 15px 0;

  @include tablet-portrait {
    margin: 20px 0;
  }

  &--bordered {
    border-top: solid 1px $uiBorder;
    padding-top: 15px;

    @include tablet-portrait {
      padding-top: 20px;
    }
  }
}

.header__menu-link {
  color: $navigation;
  display: block;

  font: {
    size: 1.6rem;
    weight: 600;
  }

  letter-spacing: 1.5px;
  // Leaving commented until fully tested
  //text-transform: capitalize;

  &::after {
    @extend %icon--arrow-right-before;
    font-size: 3rem;
    display: inline-block;
    line-height: 1;
    vertical-align: sub;
    margin-left: 5px;
  }

  @include tablet-portrait {
    font-size: 1.7rem;
    letter-spacing: 2px;
    transition: color $menuSpeed;

    &:hover {
      color: $navigationHover;
    }
  }
}

// Social Menu
.header__social {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  position: relative;
  width: calc(100% + 10px);

  @include tablet-portrait {
    justify-content: space-between;
  }
}

.header__social-item {
  margin: 0 5px;

  @include tablet-portrait {
    flex: 1;
  }
}

.header__social-link {
  background: $white;
  border: solid 1px $navigation;
  color: $navigation;
  display: block;

  font: {
    size: 0;
    weight: 600;
  }

  line-height: 3.4;
  letter-spacing: 2px;
  min-width: 45px;
  text-align: center;

  @include tablet-portrait {
    font-size: 1.2rem;
    transition: background $menuSpeed, border $menuSpeed, color $menuSpeed;

    &:hover {
      background: $navigationHover;
      border-color: $white;
      color: $white;
    }
  }

  &:before {
    display: inline-block;
    font-size: 2rem;
    line-height: 2;
    vertical-align: middle;
    width: 100%;

    @include tablet-portrait {
      font-size: 1.4rem;
      line-height: 2.8;
      margin-right: 10px;
      width: auto;
    }
  }

  &--facebook {
    @extend %icon--facebook;
    border-color: $facebook;
    color: $facebook;

    &:hover {
      background-color: $facebook;
      border-color: $facebook;
    }
  }

  &--twitter {
    @extend %icon--twitter;
    border-color: $twitter;
    color: $twitter;

    &:hover {
      background-color: $twitter;
      border-color: $twitter;
    }
  }
}

// Search Panel
.header__search {
  background: $white;
  display: block;
  height: 74px;
  position: fixed;
  top: 32px;
  width: 100%;
  z-index: $zHeader + 1;
  transform: translateX(101%);
  transition: transform $transitionSpeed;

  &--active {
    transform: translateX(0%);
  }

  .search-results-wrapper & {
    border-bottom: 1px solid $borderColor;
    transform: translateX(0%);

    @include above-phone {
      border-bottom: none;
      transform: translateX(101%);

      &--active {
        transform: translateX(0%);
      }
    }
  }

  @include above-phone {
    height: 111px;
  }
}

.header__search-field {
  background: $white;
  border: none;
  height: 100%;
  font-size: 2rem;
  left: calc(50% - 3px);
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: calc(100% - 120px);

  @include placeholder {
    font-size: 1.4rem;

    @include above-phone {
      font-size: 2rem;
    }
  }

  .search-results-wrapper & {
    left: calc(50% - 30px);
    width: calc(100% - 80px);

    @include above-phone {
      left: calc(50% - 3px);
      width: calc(100% - 180px);
    }
  }

  @include above-phone {
    font-size: 2.4rem;
    width: calc(100% - 180px);
  }
}

.feature-button--search-submit {
  right: 10px;

  @include above-phone {
    right: 30px;
  }

  &:before {
    color: $search;
  }
}
