/* ==========================================================================
	NOTIFICATIONS
========================================================================== */

.notification-bar {
  background: rgba($black, 0.8);
  color: $white;

  font: {
    size: 1.6rem;
    weight: 500;
  }

  left: 0;
  line-height: 2.8;
  opacity: 1;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include respond-to(1200px) {
    overflow: auto;
  }

  a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .notification-bar-wrap--active &,
  .notification-bar-wrap--cookie & {

    &.notification-bar__removed {
      max-height: 0px;
      min-height: 0px;
      overflow: hidden;
    }
  }

  &--cookie {
    background: rgba($black, 0.8);
  }
}

.notification-bar__wrapper {
  height: 100%;
  padding: 0 30px;
}

.notification-bar__message {
  display: block;
  float: left;
  line-height: 1.4;
  margin: 0;
  max-width: calc(100% - 60px);
  padding: 38px 0;
  vertical-align: middle;
}

.notification-bar__cookie-accept {
    background: $button;
    border-color: $brandPrimary;
    color: $white;
    display: block;
    padding: 2px 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    text-decoration: none !important;
    text-align: center;
    width: 100%;
    float: left;
    cursor: pointer;

    @include above-phone {
      display: inline;
      float: none;
      text-align: left;
      margin-bottom: 0;
      padding: 10px 20px;
      width: auto;
    }
}

.notification-bar__cookie-links {
  @include clearfix;
  display: block;
  padding: 28px 0;
  width: 100%;

  @include above-phone {
    float: right;
    width: auto;
  }

  a {
    background: $button;
    border-color: $brandPrimary;
    color: $white;
    display: block;
    padding: 2px 20px;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
    width: 100%;
    float: left;

    @include above-phone {
      display: inline;
      float: none;
      text-align: left;
      margin-bottom: 0;
      padding: 10px 20px;
      width: auto;
    }
  }
}

.notification-bar__close {
  @extend %icon--close;
  background: transparent;
  border: solid 1px $white;
  border-radius: 100%;
  display: block;

  font: {
    size: 0;
    weight: 700;
  }

  height: 30px;
  line-height: 1;
  padding: 0;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;

  &:before {
    display: block;
    font-size: 1.4rem;
    line-height: 1;
    margin-left: 0.5px;
    text-align: center;
    width: 100%;
  }

  span {
    display: none;
  }
}

.notification-bar-wrap {
  background: $white;
  box-shadow: 1px 1.732px 4px 0px rgba(0, 0, 0, 0.1);
  display: none;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 101px;
  width: 100%;
  z-index: 9989;

  &--active {
    display: block;
  }

  &--cookie {
    bottom: 0;
    display: block;
    top: auto;
  }
}

.smartbanner.smartbanner-android span.smartbanner-button-text {
    color:white;
    background:#f48300;
    box-shadow: none;
}
