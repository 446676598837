/* ==========================================================================
	VARS
========================================================================== */

// Media query breakpoints
$largerDesktop: 1800px;
$largeDesktop: 1300px;
$content: 1200px;
$tablet: 980px;
$desktop: $tablet + 1;
$tabletLandscape: 1120px;
$tabletPortrait: 768px;
$phone: 768px;
$resp2130: 2130px;
$resp1500: 1500px;
$respHeader: 1024px;
$respPromo: 820px;
$resp992: 992px;
$resp970: 970px;
$resp940: 940px;
$resp880: 880px;
$resp600: 600px;
$resp500: 500px;

// Global dimensions
$padding: 40px;

// Global colors
$exampleColor: #3d3524;

// Colors
$brandPrimary: #001E60;
$brandSecondary: #10069F;
$brandTertiary: #418FDE;
$brandPrimaryDark: #bd6600;
$brandSecondaryDark: #3a0871;
$brandTertiaryDark: #00781d;
$black: #000;
$borderColor: #d9d9d9;
$selectBorderColor: #8a8a8a;
$navigation: #323232;
$headerColor: #323232;
$white: #fff;
$offwhite: #f1f1f1;
$uiBorder: #d8d8d8;
$uiControls: #6b6b6a;
$navigationHover: #001E60;
$headerHover: #001E60;
$footer: #4a4f55;
$search: #5BC2E7;
$iconLink: #5BC2E7;
$button: #001E60;
$field: #333;
$icons: #bcbdbe;
$heading: #323232;
$paragraph: #323232;
$mapContentBg: #f1f1f1;
$navigationBg: #f1f1f1;
$navigationBorder: #c1c1c1;
$navigationColor: #222;
$mapColor: #323232;
$mapContentActive: #001E60;
$hr: #d3d3d3;
$error: #990000;
$facebook: #3b5998;
$twitter: #00aced;
$platinum: #A7A7AD;
$gold: #FEE101;
$silver: #D7D7D7;

// Animation
$delay: 1s;
$menuSpeedSlow: 0.8s;
$menuSpeed: 0.4s;
$transitionSpeed: 0.25s;
$noanimation: 0s;

// Font
$openSans: 'co-headline-regular', 'Open Sans', Arial, sans-serif;
$droidSerif: 'Droid Serif', 'Times New Roman', serif;

// Z-index
$zHeader: 997;
