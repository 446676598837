/* ==========================================================================
	BANNER AD
========================================================================== */

.modal {
    top: 0 !important;
    left: 0 !important;
    right: initial !important;
    bottom: initial !important;
    transform: none !important;
    width: 100vw !important;
    height: 100vh  !important;
    background: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
    top: 50% !important;
    left: 50% !important;
    margin: 0 !important;
    padding: 0 !important;
    transform: translate(-50%, -50%) !important;
    position: absolute !important;
    width: auto !important;
}

.bannersection--wrap {
    width: 100%;
}

.bannersection__spanlink {
    color: #333;
}

.country-hu .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-hu.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        display: block;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.country-fr .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-fr.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        display: block;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.country-de-at .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-de-at.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        display:none;
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
.country-pl .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-pl.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        display:none;
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.country-pt .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-pt.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 442px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        display:none;
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.country-fr-be .bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/banners/download-save-1860/bannersection-download-save-bg-1860-fr-be.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 336px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 320px;
        height: 67px;
        top: 585px;
        left: 177px;
        display: block;
        color:black;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.bannersection--download-save-1860 {
    display: none;
    width: 930px;
    height: 645px;
    background: url('/assets/images/newstyle/banners/download-save-1860/bannersection-download-save-bg-1860.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: block;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-1860/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 366px;
            left: 46px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-1860/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 366px;
            left: 255px;
            display: block;
            width: 204px;
            height: 61px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 320px;
        height: 67px;
        top: 543px;
        left: 177px;
        display: block;
        span {
            font-size: 1.8rem;
            line-height: 2.4rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.bannersection--download-save-700 {
    width: 350px;
    height: 275px;
    display: none;
    background: url('/assets/images/newstyle/banners/download-save-700/bannersection-download-save-bg-700.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen 
    and (orientation: landscape) {
        display: block;
    }
    @media screen and (min-width: 970px) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 177px;
            left: 17px;
            display: block;
            width: 72px;
            height: 22px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 177px;
            left: 91px;
            display: block;
            width: 72px;
            height: 22px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 120px;
        height: 11px;
        top: 248px;
        left: 65px;
        display: block;
        span {
            font-size: 0.95rem;
            line-height: 0.9rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-hu .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-hu.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 80px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: block;
        span {
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-fr .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-fr.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 80px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: block;
        span {
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-de-at .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-de-at.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 256px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 279px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: none;
        span {
            display:none;
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-pl .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-pl.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 256px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 279px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: none;
        span {
            display:none;
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-pt .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-pt.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 256px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 279px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: none;
        span {
            display:none;
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                display:none;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-fr-be .bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait-fr-be.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 14px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 248px;
            left: 80px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 55px;
        height: 10px;
        top: 332px;
        left: 80px;
        display: block;
        span {
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.bannersection--download-save-700-portrait {
    width: 350px;
    height: 415px;
    display: block;
    background: url('/assets/images/newstyle/banners/download-save-700-portrait/bannersection-download-save-bg-700-portrait.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    @media screen and (min-width: 970px) {
        display: none;
    }
    @media screen 
    and (orientation: landscape) {
        display: none;
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-700-portrait/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            top: 264px;
            left: 20px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-700-portrait/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            top: 264px;
            left: 80px;
            display: block;
            width: 59px;
            height: 18px;
            cursor: pointer;
            position: absolute;
        }
    }
    .bannersection__taxi-text {
        position: absolute;
        width: 115px;
        height: 10px;
        top: 386px;
        left: 20px;
        display: block;
        span {
            font-size: 0.9rem;
            line-height: 1.2rem;
            .bannersection__spanlink {
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
}

.country-hu .bannersection--download-save-2800 {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-hu.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-hu.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-hu.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-hu.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 28px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 40px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 133px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 192px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.country-fr .bannersection--download-save-2800 {
    @media screen and (max-width: 480px) {
        display:none;
    }
    @media screen and (max-width: 640px) {
        display:none;
    }
    @media screen and (max-width: 969px) {
        display:none;
    }
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-fr.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-fr.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-fr.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-fr.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 28px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 40px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 133px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 192px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.country-de-at .bannersection--download-save-2800 {
    @media screen and (max-width: 480px) {
        display:none;
    }
    @media screen and (max-width: 640px) {
        display:none;
    }
    @media screen and (max-width: 969px) {
        display:none;
    }
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-de-at.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-de-at.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-de-at.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-de-at.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 150px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 240px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 263px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 400px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.country-pl .bannersection--download-save-2800 {
    @media screen and (max-width: 480px) {
        display:none;
    }
    @media screen and (max-width: 640px) {
        display:none;
    }
    @media screen and (max-width: 969px) {
        display:none;
    }
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-pl.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-pl.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-pl.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-pl.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 150px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 240px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 263px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 400px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.country-pt .bannersection--download-save-2800 {
    @media screen and (max-width: 480px) {
        display:none;
    }
    @media screen and (max-width: 640px) {
        display:none;
    }
    @media screen and (max-width: 969px) {
        display:none;
    }
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-pt.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-pt.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-pt.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-pt.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 150px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 240px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 263px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 400px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.country-fr-be .bannersection--download-save-2800 {
    @media screen and (max-width: 480px) {
        display:none;
    }
    @media screen and (max-width: 640px) {
        display:none;
    }
    @media screen and (max-width: 969px) {
        display:none;
    }
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-640-fr-be.jpg') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-1568-fr-be.jpg') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-fr-be.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/banners/download-save-2800/bannersection-download-save-bg-2800-fr-be.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 28px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 40px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 133px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 192px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}

.bannersection--download-save-2800 {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    @media screen and (min-width: 320px) {
        grid-template-columns: auto 320px auto;
        height: 238px;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: auto 480px auto;
        height: 357px;
    }
    @media screen and (min-width: 640px) {
        grid-template-columns: auto 640px auto;
        height: 476px;
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: auto 768px auto;
        height: 170px;
    }
    @media screen and (min-width: 970px) {
        grid-template-columns: auto 970px auto;
        height: 215px;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: auto 1400px auto;
        height: 310px;
        border: 1px white solid;
    }
    .bannersection__bannersection--download-save-2800-col1 {      
        @media screen and (min-width: 320px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/col-left.png') repeat-x bottom;
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    .bannersection__bannersection--download-save-2800-col2 {
        background: url('/assets/images/newstyle/banners/download-save-2800/bannersection-download-save-bg-640.png') no-repeat;
        position: relative;
        @media screen and (min-width: 320px) {
            background-size: 320px auto;
        }
        @media screen and (min-width: 480px) {
            background-size: 480px auto;
        }
        @media screen and (min-width: 640px) {
            background-size: 640px auto;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/bannersection-download-save-bg-1568.png') no-repeat;
            background-size: 768px auto;
            
        }
        @media screen and (min-width: 970px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/bannersection-download-save-bg-2800.png') no-repeat;
            background-size: 970px auto;
        }
        @media screen and (min-width: 1400px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/bannersection-download-save-bg-2800.png') no-repeat;
            background-size: 1400px auto;
        }
    }
    .bannersection__bannersection--download-save-2800-col3 {
        @media screen and (min-width: 320px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/col-mob.png') repeat-x bottom;
            background-size: 1px 238px;
        }
        @media screen and (min-width: 480px) {
            background-size: 1px 357px;
        }
        @media screen and (min-width: 640px) {
            background-size: 1px 476px;
        }
        @media screen and (min-width: 768px) {
            background: url('/assets/images/newstyle/banners/download-save-2800/col-right.png') repeat-x bottom;
            background-size: 1px 142px;
        }
        @media screen and (min-width: 970px) {
            background-size: 1px 180px;
        }
        @media screen and (min-width: 1400px) {
            background-size: 1px 310px;
        }
    }
    a {
        &.bannersection__close {
            background: url('/assets/images/banners/download-save-2800/banner-close.png') no-repeat;
            background-size: 100%;
            top: 12px;
            right: 12px;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
        }
        &.bannersection__appstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-applestore.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 62px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 94px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 126px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 77px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 28px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 40px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__appstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 460px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 547px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 789px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
        &.bannersection__playstore {
            background: url('/assets/images/banners/download-save-2800/bannersection-googleplay.png') no-repeat;
            background-size: 100%;
            display: block;
            cursor: pointer;
            position: absolute;
            @media screen and (min-width: 320px) {
                top: 93px;
                left: 132px;
                width: 70px;
                height: 21px;
            }
            @media screen and (min-width: 480px) {
                top: 140px;
                left: 198px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 640px) {
                top: 187px;
                left: 265px;
                width: 136px;
                height: 41px;
            }
            @media screen and (min-width: 768px) {
                top: 141px;
                left: 160px;
                width: 81px;
                height: 25px;
            }
            @media screen and (min-width: 970px) {
                top: 165px;
                left: 133px;
                width: 103px;
                height: 31px;
            }
            @media screen and (min-width: 1400px) {
                top: 238px;
                left: 192px;
                width: 149px;
                height: 45px;
            }
            &.bannersection__playstore--taxi {
                @media screen and (min-width: 320px) {
                    top: 212px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 480px) {
                    top: 319px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 640px) {
                    top: 426px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 768px) {
                    top: 141px;
                    left: 543px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 970px) {
                    top: 165px;
                    left: 652px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1400px) {
                    top: 238px;
                    left: 941px;
                    width: 149px;
                    height: 45px;
                }
            }
        }
    }
}
.location__details {
    .bannersection--download-save-2800 {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-areas: ". . .";
        @media screen and (min-width: 320px) {
            grid-template-columns: auto 320px auto;
            height: 238px;
        }
        @media screen and (min-width: 960px) {
            grid-template-columns: auto 480px auto;
            height: 357px;
        }
        @media screen and (min-width: 1280px) {
            grid-template-columns: auto 640px auto;
            height: 476px;
        }
        @media screen and (min-width: 1536px) {
            grid-template-columns: auto 768px auto;
            height: 170px;
        }
        @media screen and (min-width: 1940px) {
            grid-template-columns: auto 970px auto;
            height: 215px;
        }
        @media screen and (min-width: 2800px) {
            grid-template-columns: auto 1400px auto;
            height: 310px;
            border: 1px white solid;
        }
        .bannersection__bannersection--download-save-2800-col1 {      
            @media screen and (min-width: 320px) {
                background-size: 1px 238px;
            }
            @media screen and (min-width: 960px) {
                background-size: 1px 357px;
            }
            @media screen and (min-width: 1280px) {
                background-size: 1px 476px;
            }
            @media screen and (min-width: 1536px) {
                background-size: 1px 142px;
            }
            @media screen and (min-width: 1940px) {
                background-size: 1px 180px;
            }
            @media screen and (min-width: 2800px) {
                background-size: 1px 310px;
            }
        }
        .bannersection__bannersection--download-save-2800-col2 {
            position: relative;
            @media screen and (min-width: 320px) {
                background-size: 320px auto;
            }
            @media screen and (min-width: 960px) {
                background-size: 480px auto;
            }
            @media screen and (min-width: 1280px) {
                background-size: 640px auto;
            }
            @media screen and (min-width: 1536px) {
                background-size: 768px auto;
                
            }
            @media screen and (min-width: 1940px) {
                background-size: 970px auto;
            }
            @media screen and (min-width: 2800px) {
                background-size: 1400px auto;
            }
        }
        .bannersection__bannersection--download-save-2800-col3 {
            @media screen and (min-width: 320px) {
                background-size: 1px 238px;
            }
            @media screen and (min-width: 960px) {
                background-size: 1px 357px;
            }
            @media screen and (min-width: 1280px) {
                background-size: 1px 476px;
            }
            @media screen and (min-width: 1536px) {
                background-size: 1px 142px;
            }
            @media screen and (min-width: 1940px) {
                background-size: 1px 180px;
            }
            @media screen and (min-width: 2800px) {
                background-size: 1px 310px;
            }
        }
        a {
            &.bannersection__close {
                background-size: 100%;
                top: 12px;
                right: 12px;
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                position: absolute;
            }
            &.bannersection__appstore {
                background-size: 100%;
                display: block;
                cursor: pointer;
                position: absolute;
                @media screen and (min-width: 320px) {
                    top: 93px;
                    left: 62px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 960px) {
                    top: 140px;
                    left: 94px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1280px) {
                    top: 187px;
                    left: 126px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 1536px) {
                    top: 141px;
                    left: 77px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 1940px) {
                    top: 165px;
                    left: 28px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 2800px) {
                    top: 238px;
                    left: 40px;
                    width: 149px;
                    height: 45px;
                }
                &.bannersection__appstore--taxi {
                    @media screen and (min-width: 320px) {
                        top: 212px;
                        left: 62px;
                        width: 70px;
                        height: 21px;
                    }
                    @media screen and (min-width: 960px) {
                        top: 319px;
                        left: 94px;
                        width: 103px;
                        height: 31px;
                    }
                    @media screen and (min-width: 1280px) {
                        top: 426px;
                        left: 126px;
                        width: 136px;
                        height: 41px;
                    }
                    @media screen and (min-width: 1536px) {
                        top: 141px;
                        left: 460px;
                        width: 81px;
                        height: 25px;
                    }
                    @media screen and (min-width: 1940px) {
                        top: 165px;
                        left: 547px;
                        width: 103px;
                        height: 31px;
                    }
                    @media screen and (min-width: 2800px) {
                        top: 238px;
                        left: 789px;
                        width: 149px;
                        height: 45px;
                    }
                }
            }
            &.bannersection__playstore {
                background-size: 100%;
                display: block;
                cursor: pointer;
                position: absolute;
                @media screen and (min-width: 320px) {
                    top: 93px;
                    left: 132px;
                    width: 70px;
                    height: 21px;
                }
                @media screen and (min-width: 960px) {
                    top: 140px;
                    left: 198px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 1280px) {
                    top: 187px;
                    left: 265px;
                    width: 136px;
                    height: 41px;
                }
                @media screen and (min-width: 1536px) {
                    top: 141px;
                    left: 160px;
                    width: 81px;
                    height: 25px;
                }
                @media screen and (min-width: 1940px) {
                    top: 165px;
                    left: 133px;
                    width: 103px;
                    height: 31px;
                }
                @media screen and (min-width: 2800px) {
                    top: 238px;
                    left: 192px;
                    width: 149px;
                    height: 45px;
                }
                &.bannersection__playstore--taxi {
                    @media screen and (min-width: 320px) {
                        top: 212px;
                        left: 132px;
                        width: 70px;
                        height: 21px;
                    }
                    @media screen and (min-width: 960px) {
                        top: 319px;
                        left: 198px;
                        width: 103px;
                        height: 31px;
                    }
                    @media screen and (min-width: 1280px) {
                        top: 426px;
                        left: 265px;
                        width: 136px;
                        height: 41px;
                    }
                    @media screen and (min-width: 1536px) {
                        top: 141px;
                        left: 543px;
                        width: 81px;
                        height: 25px;
                    }
                    @media screen and (min-width: 1940px) {
                        top: 165px;
                        left: 652px;
                        width: 103px;
                        height: 31px;
                    }
                    @media screen and (min-width: 2800px) {
                        top: 238px;
                        left: 941px;
                        width: 149px;
                        height: 45px;
                    }
                }
            }
        }
    }
}