/* ==========================================================================
	COUNTRY SELECTOR
========================================================================== */

.country-selector {
	position: relative;

	.no-touchevents &:hover:after {
		transition: border $menuSpeed;
	}

	.dropdown__menu {
		background: $brandPrimary;
		left: -10px;
		padding: 10px;
		text: {
			align: left;
			transform: uppercase;
		}
		top: calc(100% + 7px);
		width: 180px;
		z-index: 99;
	}

	.dropdown__item {
		line-height: 1.2;
	}

	.dropdown__link {
		color: $white;
		letter-spacing: normal;
	}

	&--header {
		color: $white;
		font-size: 1.3rem;
		letter-spacing: 2px;
		padding-left: 10px;

		@include tablet-portrait {
			margin: 0 10px;

			&:before,
			&:after {
				background: $white;
				content: '';
				height: calc(100% + 14px);
				left: -10px;
				position: absolute;
				top: -7px;
				width: 1px;
			}

			&:after {
				left: auto;
				right: 0;
			}
		}		
	}

	.dropdown__toggle {
		background: none;
		border: none;
		padding-right: 40px;
		position: relative;
		text-transform: uppercase;

		&:after {
			background: none;
			border: {
				bottom: solid 2px $white;
				radius: 1px;
				right: solid 2px $white;
			}
			content: '';
			display: block;
			height: 7px;
			position: absolute;
			right: 15px;
			top: calc(50% - 2px);
			transform: translateY(-50%) rotate(45deg);
			width: 7px;
			z-index: 2;
		}
	}

	&--footer {
		margin-bottom: 20px;
		position: relative;
		width: auto;

		.dropdown__menu {
			bottom: calc(100% + 7px);
			top: auto;
		}

		.dropdown__toggle:after {
			border: {
				bottom-width: 0;
				left: 2px solid $navigation;
				right-width: 0;
				top: 2px solid $navigation;
			}
			top: 50%;
		}
	}

	&:after {
		border-radius: 1px;
	}
}

.country-selector--footer {
	&:after {
		border: {
			bottom: solid 2px $navigation;
			right: solid 2px $navigation;
		}
	}

	.no-touchevents &:hover:after {
		border: {
			bottom: solid 2px $white;
			right: solid 2px $white;
		}
		transition: border $menuSpeed;
	}
}

.country-selector__item {
	appearance: none;
	background: transparent;
	border: none;
	color: $white;
	display: inline-block;
	font: {
		size: 1.2rem;
		weight: 600;
	}
	letter-spacing: 2px;
	line-height: 1;
	margin: 0;
	text-transform: uppercase;
	transition: border $menuSpeed, background $menuSpeed, color $menuSpeed;

	.country-selector--header & {
		border: {
			left: solid 1px rgba($white, 0.5);
			right: solid 1px rgba($white, 0.5);
		}
		height: 31px;
		margin: -7px 0;
		max-width: 100%;
		padding: 0 35px 0 15px;

		.no-touchevents &:hover {
			background: $white;
			color: $black;
		}
	}

	&::-ms-expand {
		display: none;
	}

	option {
		background: $white;
		border: none;
		color: $paragraph;
		display: block;
		height: auto;
		line-height: 1.4;
	}

	.country-selector--footer & {
		border: solid 1px $navigation;
		color: $navigation;
		height: 30px;
		max-width: 300px;
		padding: 0px 25px 0px 10px;

		.no-touchevents &:hover {
			background: $navigation;
			border: solid 1px $white;
			color: $white;
		}

		@include above-phone {
			max-width: 240px;
		}
	}
}