/* ==========================================================================
	FAQ
========================================================================== */

.faq-grid {
    @include above-tablet-portrait {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ". .";
        grid-gap: 30px;
    }
}

.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        margin: 0;
        padding: 0;
        .accordion__toggle {
            padding: 20px 0;
            border-top: 1px solid #c3c3c3;
            display: block;
            position: relative;
            .accordion__title {
                font-family: "Open Sans",Arial,sans-serif;
                color: #323232;
                font-size: 2rem;
                font-weight: 600;
                line-height: 1.2;
            }
            .accordion__arrow {
                position: absolute;
                right: 20px;
                top: 20px;
                background: url('/assets/images/down-arrow.svg') no-repeat center center;
                background-size: 20px auto;
                width: 20px;
                height: 20px;
                display: block;
            }
            &.open {
                .accordion__arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.accordion__inner {
    display: none;
    padding: 15px;
    a {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
        line-height: 1.6;
        margin: 0 0 20px 0;
        display: inline-block;
        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
    p {
        a{
            margin: 0;
            display: inline;
        }
    }
}