/* ==========================================================================
	FONTS
========================================================================== */

$versionNumber: "0.0.2";
$fontPath: "/assets/fonts/" !default;
$path: "/assets/icons/" !default;

@if $build == true {
	$path: "/assets/icons/";
}
@font-face {
	font-family: 'icomoon';
	src:  url('#{$path}icomoon.eot?#{$versionNumber}');
	src:  url('#{$path}icomoon.eot?#{$versionNumber}#iefix') format('embedded-opentype'),
		// url('#{$path}icomoon.woff2?#{$versionNumber}') format('woff2'),
		url('#{$path}icomoon.ttf?#{$versionNumber}') format('truetype'),
		url('#{$path}icomoon.woff?#{$versionNumber}') format('woff'),
		url('#{$path}icomoon.svg?#{$versionNumber}#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'co-headline-light';
	src:
		url('#{$fontPath}CoHeadline_W_Lt.woff2?#{$versionNumber}') format('woff2'),
		url('#{$fontPath}CoHeadline_W_Lt.woff?#{$versionNumber}') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'co-headline-regular';
	src:
		url('#{$fontPath}CoHeadline_W_Rg.woff2?#{$versionNumber}') format('woff2'),
		url('#{$fontPath}CoHeadline_W_Rg.woff?#{$versionNumber}') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'co-headline-bold';
	src:
		url('#{$fontPath}CoHeadline_W_Bd.woff2?#{$versionNumber}') format('woff2'),
		url('#{$fontPath}CoHeadline_W_Bd.woff?#{$versionNumber}') format('woff');
	font-weight: 700;
	font-style: normal;
}