/* ==========================================================================
	corona bar styles
========================================================================== */

.corona-bar {
    background: rgba(#d33822, 0.8);
    color: $white;
    text-transform: none;

    font: {
      size: 1.6rem;
      weight: 500;
    }
  
    left: 0;
    line-height: 2.8;
    opacity: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
  
    @include respond-to(1200px) {
      overflow: auto;
    }
  
    a {
      color: $white;
      font-weight: 700;
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
    }
  
    .corona-bar-wrap--active &,
    .corona-bar-wrap--cookie & {
  
      &.corona-bar__removed {
        max-height: 0px;
        min-height: 0px;
        overflow: hidden;
      }
    }
  
    &--cookie {
      background: rgba($black, 0.8);
    }
  }
  
  .corona-bar__wrapper {
    height: 100%;
    padding: 0 30px;
    text-align:center;
  }
  
  .corona-bar__message {
    display: block;
    line-height: 2.1;
    margin: 0 auto;
    // max-width: 45%;
    padding: 0.8% 0;
    vertical-align: middle;
    text-align:center;
  }
  
  .corona-bar__accept {
      background: $button;
      border-color: $brandPrimary;
      color: $white;
      display: block;
      padding: 2px 20px;
      margin-left: 20px;
      margin-bottom: 10px;
      text-decoration: none !important;
      text-align: center; 
      width: 100%;
      float: right;
      cursor: pointer;
    //   margin-left:30%;
  
      @include above-phone {
        display: inline;
        float: none;
        text-align: left;
        margin-bottom: 0;
        padding: 10px 20px;
        width: auto;
      }
  }
    
  .corona-bar__close {
    @extend %icon--close;
    background: transparent;
    border: solid 1px $white;
    border-radius: 100%;
    display: block;
  
    font: {
      size: 0;
      weight: 700;
    }
  
    height: 30px;
    line-height: 1;
    padding: 0;
    position: absolute;
    right: 30px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
  
    &:before {
      display: block;
      font-size: 1.4rem;
      line-height: 1;
      margin-left: 0.5px;
      text-align: center;
      width: 100%;
    }
  
    span {
      display: none;
    }
  }