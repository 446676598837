/* ==========================================================================
  TYPOGRAPHY
========================================================================== */

%heading {
  color: $heading;
  font-weight: 600;
  padding: 0;
  // Leaving commented until fully tested
  //text-transform: capitalize;
}

%heading--primary {
  font-size: 2.8rem;
  line-height: 1.2;
  margin-bottom: 30px;

  @include tablet {
    font-size: 4rem;
  }

  @include desktop {
    font-size: 6rem;
  }

  &:last-child {
    margin: 0;
  }
}

%heading--secondary {
  font-size: 2rem;
  letter-spacing: 0.25px;
  line-height: 1.2;
  margin-bottom: 25px;

  @include above-phone {
    font-size: 3rem;
  }

  @include desktop {
    font-size: 4rem;
  }

  &:last-child {
    margin: 0;
  }
}

%heading--tertiary {
  font-size: 1.8rem;
  letter-spacing: 1px;
  line-height: 1.4;
  margin-bottom: 25px;

  @include above-phone {
    font-size: 1.8rem;
    letter-spacing: 1.25px;
    margin-bottom: 30px;
  }

  &:last-child {
    margin: 0;
  }
}

%text {
  color: $paragraph;
  font: {
    size: 1.6rem;
    weight: 500;
  }
  line-height: 1.4;
  margin: 0 0 20px 0;
  padding: 0;

  &:last-child {
    margin: 0;
  }

  @include above-phone {
    letter-spacing: 0.4px;
    line-height: 1.6;
  }
}

%heading-eyebrow {
  color: $headerColor;
  display: inline-block;
  font: {
    size: 1.6rem;
    weight: 600;
  }
  letter-spacing: 0.4rem;
  line-height: 1.2;
  margin-bottom: 25px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;

  @include above-phone {
    font-size: 2rem;
  }

  &:after {
    background: $headerColor;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 20px;
  }


}

%heading-eyebrow--subtitle {
  font: {
    size: 1.4rem;
    weight: bold;
  }

  @include above-phone {
    font-size: 1.8rem;
  }
}

%feature-title {
  color: $white;
  font: {
    family: $droidSerif;
    size: 3rem;
    weight: 500;
  }
  margin: 0 auto;
  max-width: $resp970;
  position: relative;
  text-align: center;
  z-index: 3;

  @include above-phone {
    font-size: 4.5rem;
  }

  @include desktop {
    font-size: 6rem;
  }
}

%feature-text {
  color: $brandPrimary;
  font: {
    size: 1.4rem;
    weight: bold;
  }
  line-height: 1.2;
  margin-bottom: 20px;
  text-transform: uppercase;
}

%label-title {
  font: {
    size: 1.2rem;
    weight: bold;
  }
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px;
  // Leaving commented until fully tested
  //text-transform: capitalize;
}


%blockquote {
  color: $paragraph;
  font: {
    family: $droidSerif;
    size: 1.8rem;
    style: italic;
    weight: 600;
  }
  margin: 0 0 20px 0;
  text-transform: uppercase;

  p {
    display: inline;
  }

  &:before,
  &:after {
    display: inline-block;
    font-size: 2rem;
    line-height: 0em;
    vertical-align: middle;
  }

  &:before {
    content: open-quote;
    margin-right: 5px;
  }

  &:after {
    content: close-quote;
    margin-left: 5px;
  }
}

%wysiwyg {
  h1:not([class]) {
    @extend %heading;
    @extend %heading--primary;
  }

  h2:not([class]) {
    @extend %heading;
    @extend %heading--secondary;
  }

  h3:not([class]),
  h4:not([class]),
  h5:not([class]),
  h6:not([class]) {
    @extend %heading;
    @extend %heading--tertiary;
  }

  h4:not([class]) {
    font-size: 1.45rem;
  }

  h5:not([class]) {
    font-size: 1.325rem;
  }

  h6:not([class]) {
    font-size: 1.225rem;
  }

  p:not([class]),
  address:not([class]) {
    @extend %text;
  }

  ul:not([class]),
  ol:not([class]) {
    margin: 0 0 20px 20px;

    li {
      @extend %text;
    }
  }

  a:not([class]) {
    color: $brandPrimary;
    font-weight: bold;
    word-wrap: break-word;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    display: inline-block;
    max-width: 100%;

    &.aligncenter  {
      display: block;
      margin: 0 auto 20px auto;
    }

    &.alignright,
    &.alignleft {
      display: block;
      float: none;
      margin: 0 auto 10px;
      width: 100%;
    }

    @include above-phone {
      &.alignright {
        float: right;
        margin: 0 0 10px 20px;
        width: auto;
      }

      &.alignleft {
        float: left;
        margin: 0 10px 20px 0;
        width: auto;
      }
    }

    &.floatleft {
      float: left;
    }

    &.floatright {
      float: right;
    }
  }

  hr {
    border: none;
    border-top: solid 1px $hr;
    display: block;
    height: 1px;
    padding: 0;
    margin: 0 0 20px 0;
    width: 100%;
  }

  blockquote:not([class]) {
    @extend %blockquote;
  }
}
