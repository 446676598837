/* ==========================================================================
	PRODUCT CALLOUT
========================================================================== */

.product-callout {
  display: block;
  height: 100%;
  margin: auto;
  padding-bottom: 140px;
  position: relative;
  width: 100%;
  z-index: 3;

  @include above-phone-device {
    max-width: 300px;
  }
  @include above-tablet {
    max-width: 360px;
  }

  .banner--products & {
    background: rgba($black, .5);
    box-shadow: 0 0 7px 0 rgba($black, .25);
    max-width: 300px;
    padding-bottom: 100px;
  }
}

.product-callout__container {
  padding: 20px 0;

  .banner--products & {
    padding: 20px;
  }
}

.product-callout__title {
  @extend %heading;
  @extend %heading-eyebrow;
  color: $heading;

  &::after {
    background: $heading;
  }

  .banner--products & {
    color: $white;

    &::after {
      background: $white;
    }
  }
}

.product-callout__subtitle {
  color: $heading;
  margin: 0 0 20px;
}

.product-callout__list {
  color: $black;
  padding: 0 0 0 20px;

  .banner--products & {
    color: $white;
  }
}

.product-callout__list-item {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.product-callout__promo {
    position: absolute;
    bottom: 125px;
    right: 10px;
    width: 80px;
    height: 80px;

    img {
        width: 100%;
    }
}
.product-callout__price {
  border-top: 1px solid $hr;
  bottom: 50px;
  color: $brandPrimary;
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .2rem;
  padding: 15px 0;
  position: absolute;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  span {
    font-size: 2rem;
  }

  .banner--products & {
    padding: 15px 20px;
  }
}

.product-callout__notes {
  color: $paragraph;
  font-weight: 400;
  letter-spacing: 0;
  padding: 15px 0 0;
  text-transform: none;
}

.product-callout__submit {
  background: $button;
  bottom: 0;
  color: $white;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: .4rem;
  padding: 15px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transition: background $menuSpeed, color $menuSpeed;
  width: 100%;

  &:hover {
    background: $white;
    color: $button;
  }
}
