/* ==========================================================================
	PROMO
========================================================================== */

.promo {
	background: $black;
	border: {
		bottom: solid 1px $white;
		right: solid 1px $white;
	}
	cursor: pointer;
	display: block;
	margin: 0 -1px 0 0;
	min-height: 250px;
	overflow: hidden;
	position: relative;
	transform: translate3d(0, 0, 0);
	transition: background $menuSpeed, border $menuSpeed;
	width: calc(100% + 1px);
	@include aspect-ratio(560, 250);
	height: auto; // Overwrites aspect-ratio

	&:before {
		background: rgba($black, 0.6) !important;
		bottom: 0;
		content: '';
		display: block;
		height: 5px;
		left: 0;
		opacity: 1;
		position: absolute;
		transition: background $menuSpeed, height $menuSpeed, opacity $menuSpeed;
		width: 100%;
		z-index: 3;
	}

	.no-touchevents & {
		&:hover {
			&:before {
				background: rgba($black, 1);
				opacity: 0.6;
				height: 100%;
			}

			.promo__image {
				height: 110%;
				width: 110%;
			}
		}
	}

	&--red {
		&:before {
			background: radial-gradient(ellipse at center, #ff0000 0%, #ff0000 100%);
		}

		.no-touchevents &:hover:before {
			background: radial-gradient(ellipse at center, #ff0000 0%, #ff0000 100%);
		}
	}

	&--orange {
		&:before {
			background: radial-gradient(ellipse at center, $brandPrimary 0%, $brandPrimary 100%);
		}

		.no-touchevents &:hover:before {
			background: radial-gradient(ellipse at center, $brandPrimaryDark 0%, $brandPrimary 100%);
		}
	}

	&--purple {
		&:before {
			background: radial-gradient(ellipse at center, $brandSecondary 0%, $brandSecondary 100%);
		}

		.no-touchevents &:hover:before {
			background: radial-gradient(ellipse at center, $brandSecondaryDark 0%, $brandSecondary 100%);
		}
	}

	&--green {
		&:before {
			background: radial-gradient(ellipse at center, $brandTertiary 0%, $brandTertiary 100%);
		}

		.no-touchevents &:hover:before {
			background: radial-gradient(ellipse at center, $brandTertiaryDark 0%, $brandTertiary 100%);
		}
	}

	&--video {
		cursor: pointer;
		position: relative;
		transition: padding $menuSpeed;
		z-index: 2;
		@include aspect-ratio(560, 250);
		height: auto; // Overwrites aspect-ratio

		&:hover {
			&:before {
				display: none;
			}

			.promo__content:after {
				opacity: 1;
			}
		}

		@include above-phone {
			min-height: 470px;
			@include aspect-ratio(1680, 470);
			height: auto; // Overwrites aspect-ratio
		}

		&:after {
			background: url('/assets/images/pattern.png') 0 0 repeat transparent;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}

		.promo__content {
			@extend %icon--video;
			@extend %icon--video-filled-alt;
			text-align: center;

			&:after,
			&:before {
				border-bottom: solid 1px $white;
				color: $white;
				display: inline-block;
				font-size: 64px;
				max-width: 180px;
				padding-bottom: 25px;
				width: 100%;
			}

			&:after {
				display: none;
			}

			.promo__item {
				padding: 10px 10px 0;
				text-align: center;
			}

			@include above-phone {
				max-width: 430px;
				padding-left: 127px;
				text-align: left;

				.promo__item {
					border-left: solid 1px $white;
					padding: 16px 20px 0;
					text-align: left;
					font-family: 'co-headline-light';
					letter-spacing: 1.2px;
					font-size: 18px;
					&:first-child {
						padding-top: 0;
					}
				}

				&:after {
					opacity: 0;
					transition: opacity $menuSpeed;
				}

				&:after,
				&:before {
					border-bottom: none;
					display: block;
					left: 20px;
					padding-bottom: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 103px;
				}
			}
		}

		.promo__item {
			padding-top: 0;
			text-align: left;

			&:after {
				display: none;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&--video-active {
		@include aspect-ratio(16, 9);

		.promo__content {
			opacity: 0;
		}

		.promo__video {
			height: 100%;
			opacity: 1;
			width: 100%;
			z-index: 4;
		}
	}

	&--testimonials {
		padding: 50px 10px 50px;

		.button {
			display: block;
			margin: 40px auto 0;
			max-width: 380px;
			position: relative;
			z-index: 3;

			@include above-phone {
				margin: 0;
				max-width: 200px;
			}
		}

		.slick-dots {
			bottom: -20px;
			left: 0;
			position: absolute;
			width: 100%;

			@include above-phone {
				bottom: -27px;
				left: 230px;
				max-width: 100%;
			}
		}

		@include above-phone {
			padding: 80px 30px 30px;
		}
	}

	&--side {
		@include aspect-ratio(560, 410);
		height: auto; // Overwrites aspect-ratio
	}

	&--wash-club {
		background: rgba($black, 0.5);
		box-shadow: 0px 0px 7px 0px rgba($black, 0.25);
	}
}

.promo__video {
	display: block;
	height: 0%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity $menuSpeed $menuSpeed;
	width: 0%;
	z-index: 1;
}

.promo__image {
	background: {
		position: center;
		size: cover;
		repeat: no-repeat;
	}
	display: block;
	height: 100%;
	left: 50%;
	opacity: 0.6;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: height $menuSpeed, width $menuSpeed;
	width: 100%;
	z-index: 2;
}

.promo__content {
	@extend %wysiwyg;
	left: 50%;
	opacity: 1;
	position: absolute;
	top: 50%;
	transition: opacity $menuSpeed;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 3;

	h3,
	h4,
	h5,
	h6 {
		@extend %heading;
		@extend %heading--tertiary;
	}

	p {
		@extend %text;
	}

	.promo__item {
		color: $white;
		font-weight: 400;
		line-height: 1.4;
		margin: 0;
		padding: 16px 10px;

		@include above-phone {
			padding: 16px 20px;
		}
	}
}

.promo__item {
	position: relative;
	text: {
		align: center;
		shadow: 0px 0px 7px rgba($black, 0.5);
	}

	&:after {
		background: $white;
		box-shadow: 0px 0px 7px 0px rgba($black, 0.5);
		content: '';
		display: block;
		height: 1px;
		left: 50%;
		overflow: hidden;
		position: absolute;
		max-width: 180px;
		top: 100%;
		transform: translate(-50%, 0%);
		width: 100%;
	}

	&:last-child {
		&:after {
			display: none;
		}
	}
}

.promo__flow-content {
	position: relative;
	text-align: center;
	z-index: 3;

	@include above-phone {
		text-align: left;
	}
}

.promo__title {
	@extend %heading;
	@extend %heading--tertiary;
	color: $white;
	margin-bottom: 25px;
}

.promo__slides {
	margin: -10px auto 0 auto;
	max-width: 400px;
	width: 100%;

	@include above-phone {
		margin: -10px 0 0 0;
	}
}

.promo__slide {
	min-height: 200px;
}

.promo__quote {
	color: $white;
	font: {
		family: $droidSerif;
		size: 1.4rem;
		style: italic;
		weight: 400;
	}
	line-height: 1.67;
	text-shadow: 0px 0px 7px rgba($black, 0.5);

	@include above-phone {
		font-size: 1.8rem;
	}
}

.promo__quote-credit {
	color: $white;
	font: {
		size: 1.4rem;
		weight: 600;
	}
	line-height: 1;
	margin: 25px 0;
	text: {
		shadow: 0px 0px 7px rgba($black, 0.5);
		transform: uppercase;
	}
}
