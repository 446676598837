/* ==========================================================================
	LOCATION CONTENT
========================================================================== */

.location-content {
    color: $headerColor;
    font: {
        size: 1.2rem;
        weight: 600;
    }
    @include above-phone {
        .heading-eyebrow {
            padding-right: 30px;
        }
    }
    @include large-desktop {
        font-size: 1.6rem;
    }
}

.location-content__phone,
.location-content__address {
    display: block;
}

.location-content__phone {
    @extend %icon--phone;
    display: inline-block;
}

.location-content__directions {
    display: inline-block;
    @extend %icon--directions;
}

.location-content__description {
    color: $brandPrimary;
    margin-bottom: 15px;
}

.location-content__address {
    margin-bottom: 25px;
}

.location-content__image {
    display: block;
    height: auto;
    width: 75%;
    max-height: 400px;
}

.location-content__hours {
    time {
        display: block;
        margin-bottom: 15px;
    }
    span {
        display: inline-block;
        padding-right: 15px;
        vertical-align: top;
        width: 30%;
    }
}

.location-content__services {
    padding-left: 15px;
    li {
        list-style-type: disc;
        margin-bottom: 15px;
    }
}

.location-content__phone,
.location-content__directions,
.location-content__facebook {
    color: #004794;
    display: block;
    font-size: 1.3rem;
    margin-right: 15px;
    padding-bottom: 15px;
    @include large-desktop {
        display: inline-block;
        font-size: 1.7rem;
        margin-right: 15px;
    }
    .no-touchevents & {
        &:hover {
            color: $brandPrimary;
            &::before {
                color: $brandPrimary;
            }
        }
    }
    &::before {
        color: #004794;
        font: {
            size: 1.8rem;
            weight: bold;
        }
        padding-right: 10px;
        vertical-align: middle;
        text-decoration: none;
        @include large-desktop {
            font-size: 2.8rem;
        }
    }
}

.location-content__facebook {
    @extend %icon--facebook;
    color: #004794;
    display: inline-block;
    margin-right: 0px;
    &::before {
        color: #004794;
    }
}

.location-content .btn.btn-primary.init-modal { // WASCrm - IMOCrm (newsletter signup external code)
    // color: black;
    // margin: 0;
    // padding: 0;
    // width: 100%;
    // text-align: left;
    // font-weight: 800 !important;
    // font-size: 31px !important;

    background: $button;
    border-color: $brandPrimary;
    color: $white;
    display: block;
    padding: 2px 16px;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
    width: 100%;
    float: left;
    font-weight:800;
    border: 0px !important;
    @include above-phone {
        display: inline;
        text-align: left;
        margin-bottom: 0;
        padding: 10px 16px;
        width: auto;
        border: 0px !important;
      }


    &:hover {
        color: $brandPrimary;
        background: $white;
    }


}