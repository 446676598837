/* ==========================================================================
	HEADER-MAP
========================================================================== */

.header-map {
	left: 0;
	max-height: 100%;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	transform: translateY(-101%);
	transition: transform $menuSpeed;
	width: 100%;
	z-index: 36;

	@include above-phone {
		overflow-y: auto;

		.heading--tertiary {
			display: inline-block;
			margin: 20px;
			width: calc(100% - 90px);
		}
	}

	&--active {
		transform: translateY(0%);
	}
}
